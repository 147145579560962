<template>
  <div ref="compeition" v-lazyChart="{ fn: getData }">
    <exportBtn
      :request-func="requestFunc"
      align="left"
      :params="{ id: $route.query.id, type: 1, classNum: classNum }"
    >
      <div class="sub-title">班级竞争力</div>
    </exportBtn>
    <div class="operate">
      <div class="dataSource">
        <a-select
          v-if="category == 2 || category == 5"
          v-model="subjectId"
          style="width: 120px; margin-right: 8px"
          @change="subjectChange"
        >
          <a-select-option
            v-for="(item, index) in originData"
            :key="index"
            :value="item.subjectId"
          >
            {{ item.subjectName }}
          </a-select-option>
        </a-select>
        <a-select
          v-if="category == 5"
          v-model="params"
          not-found-content="暂无数据"
          style="width: 120px; margin-bottom: 18px"
          @change="paramsChange"
        >
          <a-select-option
            v-for="(item, index) in selectOptions"
            :key="index"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
        <a-select
          v-if="category == 3 || category == 4"
          v-model="ratio"
          not-found-content="暂无数据"
          style="width: 220px; margin-bottom: 18px"
          @change="ratioChange"
        >
          <a-select-option
            v-for="(item, index) in ratioOptions"
            :key="index"
            :value="item.name"
            >{{ item.name }}</a-select-option
          >
        </a-select>
        <template v-if="showDataSource()">
          数据源：
          <a-radio-group v-model="dataSource" @change="getData">
            <a-radio :value="1"> 原始分 </a-radio>
            <a-radio :value="2"> 赋分 </a-radio>
          </a-radio-group>
        </template>
      </div>
      <a-radio-group
        v-model="category"
        style="margin-bottom: 18px"
        button-style="solid"
        @change="radioChange"
      >
        <a-radio-button value="1">得分率</a-radio-button>
        <a-radio-button v-if="$route.query.examType != 1" value="2"
          >四分位分析</a-radio-button
        >
        <a-radio-button v-if="ratios.length" value="3">比 率</a-radio-button>
        <a-radio-button v-if="topSections.length" value="4"
          >前 N 名</a-radio-button
        >
        <a-radio-button value="5">更多指标</a-radio-button>
      </a-radio-group>
    </div>

    <template v-if="defineOptions !== null">
      <chart
        v-if="category != 2"
        :key="chartKey + 'chart'"
        :define-options="defineOptions"
        :series="series"
        :data-zoom="20"
      />
      <BoxPlot
        v-else
        :key="chartKey + 'BoxPlot'"
        show-tooltip
        :x-axis="BoxPlotXAxis"
        :source="source"
        :define-options="defineOptions"
      />
    </template>
    <template v-else>
      <no-data />
    </template>
    <exTable
      :key="chartKey + 'exTable'"
      :columns="columns"
      :data-source="tableData"
      :scroll="{ x: 'max-content' }"
      :expand-number="6"
    >
    </exTable>
    <div class="tip" style="margin-top: 18px">
      注：
      <div>
        <template v-if="category == 1">
          <div v-if="dataSource == 1">
            除赋分均分外，以上数据结果均以原始分进行统计运算。
          </div>
          <div v-if="dataSource == 2">以上数据结果均以赋分进行统计运算。</div>
          <div>以上数据均不包括不参与统计的考生成绩</div>
          <div>
            排名指当前班级内参考学科在全年级内的原始分均分排名名次，总分的排名为班级赋分均分在年级大类中排名
          </div>
        </template>
        <template v-else>
          <div>
            1、以上数据结果均以{{
              dataSource == 1 ? "原始分" : "赋分"
            }}进行统计运算；
          </div>
          <div>2、以上数据均不包括不参与统计的考生成绩</div>
          <div v-if="category !== 5">
            排名指当前班级内参考学科在全年级内的{{
              dataSource == 1 ? "原始分" : "赋分"
            }}均分排名名次，总分的排名为班级赋分均分在年级大类中排名
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import chart from "../../components/charts.vue";
import exTable from "../../components/exTable.vue";

import BoxPlot from "./components/BoxPlot.vue";
import { getCompetion } from "@/core/api/newExamAcademic/director";
import { classcompetitive } from "@/core/api/newExamAcademic/union/head";
import { exportHeadReportNew } from "@/core/api/academic/headReport";
import exportBtn from "@/views/academic/components/exportBtn.vue";
// import { getHexColor } from "@/core/util/util";
export default {
  name: "",
  components: {
    chart,
    exTable,
    BoxPlot,
    exportBtn,
  },
  props: {
    classNum: {
      type: String,
      default: "",
    },
    eleccombId: {
      type: [String, Number],
      default: "",
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataSource: 1,
      category: "1",
      requestFunc: exportHeadReportNew,
      ratios: [],
      topSections: [],
      defineOptions: null,
      series: [],
      columns: [],
      tableData: [],
      chartKey: Math.random(),
      selectOptions: [],
      radioFunc: {
        1: this.setRate,
        2: this.setQuartile,
        3: this.setRatio,
        4: this.setTop,
        5: this.setMore,
      },
      ratioOptions: [],
      ratio: "",
      params: "median",
      subjectId: 0,
      BoxPlotXAxis: [],
      subjectList: [
        {
          subjectName: "总分",
          id: 0,
        },
      ],
      scoringRate: [
        {
          dataIndex: "subjectName",
          title: "学科",
          align: "center",
          width: 75,
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "averageScoreOri",
          title: "平均分",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "averageScoreRank",
          title: "排名",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "averageScore",
          title: "赋分均分",
          align: "center",
          width: 100,
          customRender: (text, record) => {
            if ([1, 2, 3, 4, 8].includes(record.subjectId)) {
              return "-";
            } else {
              return record.averageScore || "";
            }
          },
        },
        {
          dataIndex: "no1ScoreRateOri",
          title: "第一名得分率",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "previousScoreRateOri",
          title: "上一名得分率",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "scoreRateOri",
          title: "本班得分率",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "nextScoreRateOri",
          title: "下一名得分率",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "gradeScoreRateOri",
          title: "年级得分率",
          align: "center",
          width: 120,
          customRender: (text, record) => {
            let { gradeScoreRateOri, scoreRateOri } = record;
            if (
              gradeScoreRateOri !== null &&
              scoreRateOri !== null &&
              gradeScoreRateOri !== 0
            ) {
              if (gradeScoreRateOri > scoreRateOri) {
                let diff = Math.floor(gradeScoreRateOri - scoreRateOri);
                return (
                  <span>
                    {gradeScoreRateOri}{" "}
                    <span style="color:#7AB5EF">+{diff}</span>{" "}
                  </span>
                );
              } else {
                let diff = Math.floor(scoreRateOri - gradeScoreRateOri);
                return (
                  <span>
                    {gradeScoreRateOri}{" "}
                    <span style="color:#FF0000">-{diff}</span>{" "}
                  </span>
                );
              }
            }
            return gradeScoreRateOri || "";
          },
        },
        {
          dataIndex: "medianOri",
          title: "中位数",
          align: "center",
          width: 85,
          customRender: (text, record) =>
            record["median" + (this.dataSource == 1 ? "Ori" : "")],
        },
        {
          dataIndex: "top27AverageScoreOri",
          title: "前27%均分",
          align: "center",
          width: 120,
        },
        {
          dataIndex: "after27AverageScoreOri",
          title: "后27%均分",
          align: "center",
          width: 120,
        },
        {
          dataIndex: "discrimination",
          title: "区分度",
          align: "center",
          width: 85,
          customRender: (text, record) => {
            let discrimination =
              record["discrimination" + (this.dataSource == 1 ? "Ori" : "")];
            if (discrimination === null) {
              return "";
            }

            return discrimination.toFixed(2);
          },
        },
      ],
      unionRateCols: [
        {
          dataIndex: "subjectName",
          title: "学科",
          align: "center",
          width: 75,
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "averageScoreOri",
          slots: { title: "averageScoreOri" },
          align: "center",
        },
        {
          dataIndex: "averageScoreRank",
          title: "校排名",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "averageScoreUnionRank",
          title: "整体排名",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "averageScore",
          title: "赋分均分",
          align: "center",
          width: 100,
          customRender: (text, record) =>
            [1, 2, 3, 4, 8].includes(record.subjectId) ? "-" : text,
        },
        {
          dataIndex: "scoreRateOri",
          title: "本班得分率",
          align: "center",
          width: 120,
        },
        {
          dataIndex: "no1ScoreRateOri",
          title: "整体高位班级得分率",
          align: "center",
          width: 125,
        },
        {
          dataIndex: "previousScoreRateOri",
          title: "上位班级得分率",
          align: "center",
          width: 100,
          customRender: (text, record) =>
            record[
              "previousScoreRate" + (this.dataSource == 1 ? "Ori" : "")
            ] === null
              ? "-"
              : record[
                  "previousScoreRate" + (this.dataSource == 1 ? "Ori" : "")
                ],
        },
        {
          dataIndex: "nextScoreRateOri",
          title: "下位班级得分率",
          align: "center",
          width: 100,
          customRender: (text, record) =>
            record["nextScoreRate" + (this.dataSource == 1 ? "Ori" : "")] ===
            null
              ? "-"
              : record["nextScoreRate" + (this.dataSource == 1 ? "Ori" : "")],
        },
        {
          dataIndex: "gradeScoreRateOri",
          title: "年级得分率/差值",
          align: "center",
          width: 150,
          customRender: (text, record) =>
            (record.gradeScoreRateOri === null
              ? "-"
              : record.gradeScoreRateOri) +
            "/" +
            (record.gradeScoreRateOri - record.scoreRateOri).toFixed(2),
        },
        {
          dataIndex: "unionScoreRateOri",
          title: "整体得分率/差值",
          align: "center",
          width: 150,
          customRender: (text, record) =>
            (record.unionScoreRateOri === null
              ? "-"
              : record.unionScoreRateOri) +
            "/" +
            (record.unionScoreRateOri - record.scoreRateOri).toFixed(2),
        },
        {
          dataIndex: "top27AverageScoreOri",
          title: "前27%均分",
          align: "center",
          width: 125,
        },
        {
          dataIndex: "after27AverageScoreOri",
          title: "后27%均分",
          align: "center",
          width: 125,
        },
        {
          dataIndex: "discriminationOri",
          title: "区分度",
          align: "center",
          width: 100,
        },
      ],
      source: [],
      beCurrent: [
        {
          dataIndex: "classNum",
          title: "班级",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "median",
          title: "中位数",
          align: "center",
          width: 100,
          customRender: (text, record) =>
            record["median" + (this.dataSource === 1 ? "Ori" : "")],
        },
        {
          dataIndex: "upperWhisker",
          title: "上限",
          align: "center",
          width: 100,
          customRender: (text, record) =>
            record["upperWhisker" + (this.dataSource === 1 ? "Ori" : "")],
        },
        {
          dataIndex: "highestScore",
          title: "最高分",
          align: "center",
          width: 100,
          customRender: (text, record) =>
            record["highestScore" + (this.dataSource === 1 ? "Ori" : "")],
        },
        {
          dataIndex: "lowerWhisker",
          title: "下限",
          align: "center",
          width: 100,
          customRender: (text, record) =>
            record["lowerWhisker" + (this.dataSource === 1 ? "Ori" : "")],
        },
        {
          dataIndex: "lowestScore",
          title: "最低分",
          align: "center",
          width: 100,
          customRender: (text, record) =>
            record["lowestScore" + (this.dataSource === 1 ? "Ori" : "")],
        },
        {
          dataIndex: "interQuartileRange",
          title: "四分位间距",
          align: "center",
          width: 100,
          customRender: (text, record) =>
            record["interQuartileRange" + (this.dataSource === 1 ? "Ori" : "")],
        },
        {
          dataIndex: "RangeScore",
          title: "全距/极差",
          align: "center",
          width: 100,
          customRender: (text, record) =>
            record["RangeScore" + (this.dataSource === 1 ? "Ori" : "")],
        },
      ],
      moreCols: [
        {
          dataIndex: "subjectName",
          title: "科目",
          align: "center",
          width: 75,
        },
        {
          dataIndex: "teacherName",
          title: "任课教师",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "median",
          title: "中位数",
          align: "center",
          width: 85,
          customRender: (text, record) =>
            record["median" + (this.dataSource == 1 ? "Ori" : "")],
        },
        {
          dataIndex: "highestScore",
          title: "最高分",
          align: "center",
          width: 85,
          customRender: (text, record) =>
            record["highestScore" + (this.dataSource == 1 ? "Ori" : "")],
        },
        {
          dataIndex: "lowestScore",
          title: "最低分",
          align: "center",
          width: 85,
          customRender: (text, record) =>
            record["lowestScore" + (this.dataSource == 1 ? "Ori" : "")],
        },
        {
          dataIndex: "standardDeviation",
          title: "标准差",
          align: "center",
          width: 85,
          customRender: (text, record) =>
            record["standardDeviation" + (this.dataSource == 1 ? "Ori" : "")],
        },
        {
          dataIndex: "variance",
          title: "方差",
          align: "center",
          width: 100,
          customRender: (text, record) =>
            record["variance" + (this.dataSource == 1 ? "Ori" : "")],
        },
        {
          dataIndex: "coefficientOfVariation",
          title: "差异系数",
          align: "center",
          width: 100,
          customRender: (text, record) =>
            record[
              "coefficientOfVariation" + (this.dataSource == 1 ? "Ori" : "")
            ],
        },
        {
          dataIndex: "discrimination",
          title: "区分度",
          align: "center",
          width: 85,
          customRender: (text, record) => {
            let discrimination =
              record["discrimination" + (this.dataSource == 1 ? "Ori" : "")];
            if (discrimination === null) {
              return "";
            }

            return discrimination;
          },
        },
        {
          dataIndex: "scoreRate",
          title: "得分率",
          align: "center",
          width: 85,
          customRender: (text, record) => {
            let scoreRate =
              record["scoreRate" + (this.dataSource == 1 ? "Ori" : "")];
            if (scoreRate === null) {
              return "";
            }
            return scoreRate + "%";
          },
        },
        {
          dataIndex: "overAverageRate",
          title: "超均率",
          align: "center",
          width: 100,
          customRender: (text, record) => {
            let overAverageRate =
              record["overAverageRate" + (this.dataSource == 1 ? "Ori" : "")];
            if (overAverageRate === null) {
              return "";
            }
            return overAverageRate + "%";
          },
        },
      ],
      unionCols: [
        {
          dataIndex: "classNum",
          title: "班级",
          align: "center",
          width: 75,
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          align: "center",
          width: 75,
          customRender: (text, record) => {
            if (["上位班级", "下位班级"].includes(record.classNum)) {
              if (text === null) {
                return "-";
              }
              return text;
            }
            return text;
          },
        },
        {
          dataIndex: "highestScore",
          title: "最高分",
          align: "center",
          width: 75,
          customRender: (text, record) => {
            if (["上位班级", "下位班级"].includes(record.classNum)) {
              if (text === null) {
                return "-";
              }
              return text;
            }
            return text;
          },
        },
        {
          dataIndex: "lowestScore",
          title: "最低分",
          align: "center",
          width: 75,
          customRender: (text, record) => {
            if (["上位班级", "下位班级"].includes(record.classNum)) {
              if (text === null) {
                return "-";
              }
              return text;
            }
            return text;
          },
        },
        {
          dataIndex: "rangeScore",
          title: "全距",
          align: "center",
          width: 75,
          customRender: (text, record) => {
            if (["上位班级", "下位班级"].includes(record.classNum)) {
              if (text === null) {
                return "-";
              }
              return text;
            }
            return text;
          },
        },
        {
          dataIndex: "median",
          title: "中位数",
          align: "center",
          width: 75,
          customRender: (text, record) => {
            if (["上位班级", "下位班级"].includes(record.classNum)) {
              if (text === null) {
                return "-";
              }
              return text;
            }
            return text;
          },
        },
        {
          dataIndex: "unionOverAverageRate",
          title: "整体超均率",
          align: "center",
          width: 120,
          customRender: (text, record) => {
            if (["整体"].includes(record.classNum)) {
              return "-";
            } else if (["上位班级", "下位班级"].includes(record.classNum)) {
              if (text === null) {
                return "-";
              }
              return text;
            }
            return record.unionOverAverageRate;
          },
        },
        {
          dataIndex: "overAverageRate",
          title: "校超均率",
          align: "center",
          width: 100,
          customRender: (text, record) => {
            if (["学校", "整体"].includes(record.classNum)) {
              return "-";
            } else if (["上位班级", "下位班级"].includes(record.classNum)) {
              if (text === null) {
                return "-";
              }
              return text;
            }
            return record.overAverageRate;
          },
        },
        {
          dataIndex: "standardDeviation",
          title: "标准差",
          align: "center",
          width: 75,
          customRender: (text, record) => {
            if (["上位班级", "下位班级"].includes(record.classNum)) {
              if (text === null) {
                return "-";
              }
              return text;
            }
            return text;
          },
        },
        {
          dataIndex: "coefficientOfVariation",
          title: "差异系数",
          align: "center",
          width: 100,
          customRender: (text, record) => {
            if (["上位班级", "下位班级"].includes(record.classNum)) {
              if (text === null) {
                return "-";
              }
              return text;
            }
            return text;
          },
        },
      ],
      moreDataModel: [
        {
          classNum: "整体高位班级",
          preix: "no1",
        },
        {
          classNum: "上位班级",
          preix: "previous",
        },
        {
          classNum: "本班",
          preix: null,
        },
        {
          classNum: "下位班级",
          preix: "next",
        },
        {
          classNum: "学校",
          preix: "grade",
        },
        {
          classNum: "整体",
          preix: "union",
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.examType == 1) {
      this.selectOptions = [
        {
          label: "中位数",
          value: "median",
        },
        {
          label: "最高分",
          value: "highestScore",
        },
        {
          label: "最低分",
          value: "lowestScore",
        },
        {
          label: "整体超均率",
          value: "overAverageRateUnion",
        },
        {
          label: "校超均率",
          value: "overAverageRate",
        },
        {
          label: "标准差",
          value: "standardDeviation",
        },
        {
          label: "差异系数",
          value: "coefficientOfVariation",
        },
      ];
    } else {
      this.selectOptions = [
        {
          label: "方差",
          value: "variance",
        },
        {
          label: "差异系数",
          value: "coefficientOfVariation",
        },
        {
          label: "得分率",
          value: "scoreRate",
        },
        {
          label: "超均率",
          value: "overAverageRate",
        },
        {
          label: "区分度",
          value: "discrimination",
        },
        {
          label: "标准差",
          value: "standardDeviation",
        },
        {
          label: "中位数",
          value: "median",
        },
        {
          label: "最高分",
          value: "highestScore",
        },
        {
          label: "最低分",
          value: "lowestScore",
        },
      ];
    }
  },
  methods: {
    showDataSource() {
      return (
        ![1, 2, 3, 4, 8].includes(this.subjectId) &&
        ["2", "3", "4", "5"].includes(this.category)
      );
    },
    capitalizeFirstLetter(string) {
      // 首字母大写
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async getData(loadingInstance, io) {
      let res;
      if (this.$route.query.examType == 1) {
        res = await classcompetitive({
          id: this.$route.query.id,
          classNum: this.classNum,
          eleccombId: this.eleccombId,
          subjectId: this.subjectId,
          dataSource: this.dataSource,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getCompetion({
          id: this.$route.query.id,
          classNum: this.classNum,
          eleccombId: this.eleccombId,
        }).catch(() => {
          this.closeLoading(loadingInstance, io);
        });
      }

      let main = res.data.data.filter((item) =>
        [0, 1, 2, 3, 4, 8].includes(item.subjectId)
      );
      let other = res.data.data.filter(
        (item) => ![0, 1, 2, 3, 4, 8].includes(item.subjectId)
      );
      this.originData = [...main, ...other];
      this.tableData = [...this.originData];
      if (this.tableData.length) {
        this.ratios = this.tableData[0].ratios;
        this.topSections = this.tableData[1].topSections;
      }
      this.radioChange();
      this.closeLoading(loadingInstance, io);
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.compeition);
        });
      }
    },
    subjectChange() {
      if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
        this.dataSource = 1;
      }
      if (this.category == 2) {
        this.setQuartile();
      } else {
        this.setMore();
      }
    },
    radioChange() {
      this.defineOptions = null;
      this.columns = [];
      this.series = [];
      this.ratio = null;
      this.ratioOptions = [];
      this.tableData = [...this.originData];
      this.radioFunc[this.category]();
      if (this.$route.query.examType == 1) {
        if (["4"].includes(this.category)) {
          this.tableData = this.originData.filter(
            (item) => item.subjectId != 0
          );
        } else if (this.category != 5) {
          this.tableData = [...this.originData];
        }
      }
    },
    titleCase(str) {
      var newStr = str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
      return newStr;
    },
    paramsChange() {
      this.defineOptions = null;
      this.series = [];
      this.setMore();
    },
    setRate() {
      let unionCols = this.unionRateCols;
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore != 1) {
        unionCols = this.unionRateCols.filter((item) => {
          if (item.title) {
            if (item.title.indexOf("整体") == -1) {
              return item;
            }
          } else {
            return item;
          }
        });
      }
      if (this.$parent.topData.isSchoolDisplayReportClassRank != 1) {
        unionCols = unionCols.filter((item) => {
          if (item.title) {
            if (item.title !== "校排名" && item.title !== "整体排名") {
              return item;
            }
          } else {
            return item;
          }
        });
      }
      this.columns =
        this.$route.query.examType == 1 ? unionCols : this.scoringRate;
      this.defineOptions = {
        xAxis: {
          axisTick: {
            show: false,
          },
          type: "category",
          data: [...this.tableData.map((item) => item.subjectName)],
        },
      };
      this.series = [
        {
          name: "本班",
          type: "bar",
          color: "#619AF1",
          data: [...this.tableData.map((item) => item["scoreRateOri"])],
          barMaxWidth: 40,

          label: {
            show: true,
            position: "top",
            color: "inherit",
            formatter: ({ value }) => value + "%",
          },
        },
        {
          name: this.$route.query.examType == 1 ? "上位班级" : "上一名班级",
          type: "line",
          color: "#5D7092",
          data: [...this.tableData.map((item) => item["previousScoreRateOri"])],
          smooth: true,
        },
        {
          name: this.$route.query.examType == 1 ? "下位班级" : "下一名班级",
          type: "line",
          color: "#5B8FF9",
          data: [...this.tableData.map((item) => item["nextScoreRateOri"])],
          smooth: true,
        },
        // {
        //   name: "第一名班级",
        //   type: "line",
        //   color: "#E8684A",
        //   data: [...this.tableData.map((item) => item["gradeScoreRateOri"])],
        // smooth:true
        // },
        {
          name: this.$route.query.examType == 1 ? "学校" : "全校平均值",
          type: "line",
          color: "#F6BD16",
          data: [...this.tableData.map((item) => item["gradeScoreRateOri"])],
          smooth: true,
        },
      ];
      if (this.$route.query.examType == 1) {
        this.series.push({
          name: "高位班级",
          type: "line",
          color: "#5D7092",
          data: [...this.tableData.map((item) => item["no1ScoreRateOri"])],
          smooth: true,
        });
        if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          this.series.push({
            name: "整体",
            type: "line",
            color: "#5D7092",
            data: [...this.tableData.map((item) => item["unionScoreRateOri"])],
            smooth: true,
          });
        }
      }
      this.chartKey = Math.random();
    },
    setQuartile() {
      let subject = this.originData.find(
        (item) => item.subjectId === this.subjectId
      );
      this.tableData = [
        {
          classNum: "第一名",
          statTotal: "-",
          median: subject.no1Median,
          medianOri: subject.no1MedianOri,
          upperWhisker: subject.no1UpperWhisker,
          upperWhiskerOri: subject.no1UpperWhiskerOri,
          highestScore: subject.no1HighestScore,
          highestScoreOri: subject.no1HighestScoreOri,
          lowerWhisker: subject.no1LowerWhisker,
          lowerWhiskerOri: subject.no1LowerWhiskerOri,
          lowestScore: subject.no1LowestScore,
          lowestScoreOri: subject.no1LowestScoreOri,
          interQuartileRange: subject.no1InterQuartileRange,
          interQuartileRangeOri: subject.no1InterQuartileRangeOri,
          RangeScore: subject.no1RangeScore,
          RangeScoreOri: subject.no1RangeScoreOri,
        },
        {
          classNum: "上一名",
          statTotal: "-",
          median: subject.previousMedian,
          medianOri: subject.previousMedianOri,
          upperWhisker: subject.previousUpperWhisker,
          upperWhiskerOri: subject.previousUpperWhiskerOri,
          highestScore: subject.previousHighestScore,
          highestScoreOri: subject.previousHighestScoreOri,
          lowerWhisker: subject.previousLowerWhisker,
          lowerWhiskerOri: subject.previousLowerWhiskerOri,
          lowestScore: subject.previousLowerWhisker,
          lowestScoreOri: subject.previousLowerWhiskerOri,
          interQuartileRange: subject.previousInterQuartileRange,
          interQuartileRangeOri: subject.previousInterQuartileRangeOri,
          RangeScore: subject.previousRangeScore,
          RangeScoreOri: subject.previousRangeScoreOri,
        },
        {
          classNum: "本班",
          statTotal: subject.statTotal,
          median: subject.median,
          medianOri: subject.medianOri,
          upperWhisker: subject.upperWhisker,
          upperWhiskerOri: subject.upperWhiskerOri,
          highestScore: subject.highestScore,
          highestScoreOri: subject.highestScoreOri,
          lowerWhisker: subject.lowerWhisker,
          lowerWhiskerOri: subject.lowerWhiskerOri,
          lowestScore: subject.lowestScore,
          lowestScoreOri: subject.lowestScoreOri,
          interQuartileRange: subject.interQuartileRange,
          interQuartileRangeOri: subject.interQuartileRangeOri,
          RangeScore: subject.rangeScore,
          RangeScoreOri: subject.rangeScoreOri,
        },
        {
          classNum: "下一名",
          statTotal: "-",
          median: subject.nextMedian,
          medianOri: subject.nextMedianOri,
          upperWhisker: subject.nextUpperWhisker,
          upperWhiskerOri: subject.nextUpperWhiskerOri,
          highestScore: subject.nextHighestScore,
          highestScoreOri: subject.nextHighestScoreOri,
          lowerWhisker: subject.nextLowerWhisker,
          lowerWhiskerOri: subject.nextLowerWhiskerOri,
          lowestScore: subject.nextLowestScore,
          lowestScoreOri: subject.nextLowestScoreOri,
          interQuartileRange: subject.nextInterQuartileRange,
          interQuartileRangeOri: subject.nextInterQuartileRangeOri,
          RangeScore: subject.nextRangeScore,
          RangeScoreOri: subject.nextRangeScoreOri,
        },
      ];
      if (this.tableData.length) {
        this.BoxPlotXAxis = ["第一名", "上一名", "本班", "下一名"];
        this.columns = [...this.beCurrent];
        let subject = this.originData.find(
          (item) => item.subjectId == this.subjectId
        );
        if (subject) {
          this.source = [
            [
              subject["no1UpperQuartile" + (this.dataSource == 1 ? "Ori" : "")],
              subject["no1LowerQuartile" + (this.dataSource == 1 ? "Ori" : "")],
              subject["no1Median" + (this.dataSource == 1 ? "Ori" : "")],
              subject["no1UpperWhisker" + (this.dataSource == 1 ? "Ori" : "")],
              subject["no1LowerWhisker" + (this.dataSource == 1 ? "Ori" : "")],
            ],
            [
              subject[
                "previouslowerWhisker" + (this.dataSource == 1 ? "Ori" : "")
              ],
              subject[
                "previousLowerQuartile" + (this.dataSource == 1 ? "Ori" : "")
              ],
              subject["previousMedian" + (this.dataSource == 1 ? "Ori" : "")],
              subject[
                "previousUpperQuartile" + (this.dataSource == 1 ? "Ori" : "")
              ],
              subject[
                "previousupperWhisker" + (this.dataSource == 1 ? "Ori" : "")
              ],
            ],
            [
              subject[
                "previouslowerWhisker" + (this.dataSource == 1 ? "Ori" : "")
              ],
              subject[
                "previousLowerQuartile" + (this.dataSource == 1 ? "Ori" : "")
              ],
              subject["previousMedian" + (this.dataSource == 1 ? "Ori" : "")],
              subject[
                "previousUpperQuartile" + (this.dataSource == 1 ? "Ori" : "")
              ],
              subject[
                "previousupperWhisker" + (this.dataSource == 1 ? "Ori" : "")
              ],
            ],
            [
              subject["lowerWhisker" + (this.dataSource == 1 ? "Ori" : "")],
              subject["lowerQuartile" + (this.dataSource == 1 ? "Ori" : "")],
              subject["median" + (this.dataSource == 1 ? "Ori" : "")],
              subject["upperQuartile" + (this.dataSource == 1 ? "Ori" : "")],
              subject["upperWhisker" + (this.dataSource == 1 ? "Ori" : "")],
            ],
            [
              subject["nextlowerWhisker" + (this.dataSource == 1 ? "Ori" : "")],
              subject[
                "nextLowerQuartile" + (this.dataSource == 1 ? "Ori" : "")
              ],
              subject["nextMedian" + (this.dataSource == 1 ? "Ori" : "")],
              subject[
                "nextUpperQuartile" + (this.dataSource == 1 ? "Ori" : "")
              ],
              subject["nextupperWhisker" + (this.dataSource == 1 ? "Ori" : "")],
            ],
          ];
          this.defineOptions = {
            series: [
              {
                name: subject.subjectName,
                type: "boxplot",
                datasetIndex: 1,
                itemStyle: {
                  color: "#7AB5EF",
                },
                boxWidth: [20, 20],
              },
              {
                name: subject.subjectName,
                type: "scatter",
                datasetIndex: 2,
                itemStyle: {
                  color: "#4589EF",
                },
                symbolSize: 4,
              },
              {
                type: "line",
                name: subject.subjectName + "最高分",
                symbol: "none",
                lineStyle: {
                  type: "dashed",
                },
                color: "#FF0000",
                data: [
                  subject[
                    "gradeHighestScore" + (this.dataSource == 1 ? "Ori" : "")
                  ],
                  subject[
                    "gradeHighestScore" + (this.dataSource == 1 ? "Ori" : "")
                  ],
                  subject[
                    "gradeHighestScore" + (this.dataSource == 1 ? "Ori" : "")
                  ],
                  subject[
                    "gradeHighestScore" + (this.dataSource == 1 ? "Ori" : "")
                  ],
                ],
              },
              {
                type: "line",
                name: subject.subjectName + "最低分",
                symbol: "none",
                lineStyle: {
                  type: "dashed",
                },
                data: [
                  subject[
                    "gradeLowestScore" + (this.dataSource == 1 ? "Ori" : "")
                  ],
                  subject[
                    "gradeLowestScore" + (this.dataSource == 1 ? "Ori" : "")
                  ],
                  subject[
                    "gradeLowestScore" + (this.dataSource == 1 ? "Ori" : "")
                  ],
                  subject[
                    "gradeLowestScore" + (this.dataSource == 1 ? "Ori" : "")
                  ],
                ],
                color: "#ED6024",
              },
              {
                type: "line",
                name: subject.subjectName + "中位数",
                symbol: "none",
                lineStyle: {
                  type: "dashed",
                },
                data: [
                  subject["gradeMedian" + (this.dataSource == 1 ? "Ori" : "")],
                  subject["gradeMedian" + (this.dataSource == 1 ? "Ori" : "")],
                  subject["gradeMedian" + (this.dataSource == 1 ? "Ori" : "")],
                  subject["gradeMedian" + (this.dataSource == 1 ? "Ori" : "")],
                ],
                color: "#FFAB00",
              },
            ],
          };
        }
        this.chartKey = Math.random();
      }
    },
    ratioChange() {
      if (this.category == 3) {
        this.setRatioChart();
      } else {
        this.setTopSectionChart();
      }
    },
    setRatioChart() {
      this.defineOptions = {
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: [...this.tableData.map((item) => item.subjectName)],
        },
        legend: {
          itemGap: 18,
          type: "scroll",
        },
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
      };
      let data = this.tableData.map((item) =>
        item.ratios.find((item) => item.name === this.ratio)
      );
      this.series = [
        {
          type: "bar",
          barMaxWidth: 40,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          name: "本班" + this.ratio,
          data: [
            ...data.map(
              (item) => item["proportion" + (this.dataSource == 1 ? "Ori" : "")]
            ),
          ],
        },
        {
          type: "line",
          name:
            (this.$route.query.examType == 1 ? "高位班级" : "第一名") +
            this.ratio,
          smooth: true,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          data: [
            ...data.map(
              (item) =>
                item["no1Proportion" + (this.dataSource == 1 ? "Ori" : "")]
            ),
          ],
        },
        {
          type: "line",
          name:
            (this.$route.query.examType == 1 ? "上位班级" : "上一名") +
            this.ratio,
          smooth: true,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          data: [
            ...data.map(
              (item) =>
                item["previousProportion" + (this.dataSource == 1 ? "Ori" : "")]
            ),
          ],
        },

        {
          type: "line",
          name:
            (this.$route.query.examType == 1 ? "下位班级" : "下一名") +
            this.ratio,
          smooth: true,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          data: [
            ...data.map(
              (item) =>
                item["nextProportion" + (this.dataSource == 1 ? "Ori" : "")]
            ),
          ],
        },
      ];
      if (this.$route.query.examType == 1) {
        this.series.push({
          type: "line",
          name: "本校" + this.ratio,
          smooth: true,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          data: [
            ...data.map(
              (item) =>
                item["gradeProportion" + (this.dataSource == 1 ? "Ori" : "")]
            ),
          ],
        });
        if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          this.series.push({
            type: "line",
            name: "整体" + this.ratio,
            smooth: true,
            label: {
              show: true,
              position: "top",
              color: "inherit",
            },
            data: [
              ...data.map(
                (item) =>
                  item["unionProportion" + (this.dataSource == 1 ? "Ori" : "")]
              ),
            ],
          });
        }
      }
      this.chartKey = Math.random();
    },
    setTopSectionChart() {
      this.defineOptions = {
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: [...this.tableData.map((item) => item.subjectName)],
        },
        legend: {
          itemGap: 18,
          type: "scroll",
        },
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
      };
      let data = this.tableData.map((item) =>
        item.topSections.find((item) => item.name === this.ratio)
      );
      this.series = [
        {
          type: "bar",
          barMaxWidth: 40,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          name: "本班" + this.ratio,
          data: data.map((item) => {
            if (item) {
              return item["proportion" + (this.dataSource == 1 ? "Ori" : "")];
            }
          }),
        },
        {
          type: "line",
          name:
            (this.$route.query.examType == 1 ? "高位班级" : "第一名") +
            this.ratio,
          smooth: true,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          data: data.map((item) => {
            if (item) {
              return item[
                "no1Proportion" + (this.dataSource == 1 ? "Ori" : "")
              ];
            }
          }),
        },
        {
          type: "line",
          smooth: true,
          name:
            (this.$route.query.examType == 1 ? "上位班级" : "上一名") +
            this.ratio,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          data: data.map((item) => {
            if (item) {
              return item[
                "previousProportion" + (this.dataSource == 1 ? "Ori" : "")
              ];
            }
          }),
        },

        {
          type: "line",
          smooth: true,
          name:
            (this.$route.query.examType == 1 ? "下位班级" : "下一名") +
            this.ratio,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          data: data.map((item) => {
            if (item) {
              return item[
                "nextProportion" + (this.dataSource == 1 ? "Ori" : "")
              ];
            }
          }),
        },
      ];
      if (this.$route.query.examType == 1) {
        this.series.push({
          type: "line",
          name: "本校" + this.ratio,
          smooth: true,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          data: [
            ...data.map((item) => {
              if (item) {
                return item[
                  "gradeProportion" + (this.dataSource == 1 ? "Ori" : "")
                ];
              }
            }),
          ],
        });
        if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          this.series.push({
            type: "line",
            name: "整体" + this.ratio,
            smooth: true,
            label: {
              show: true,
              position: "top",
              color: "inherit",
            },
            data: [
              ...data.map((item) => {
                if (item) {
                  return item[
                    "unionProportion" + (this.dataSource == 1 ? "Ori" : "")
                  ];
                }
              }),
            ],
          });
        }
      }
      this.chartKey = Math.random();
    },
    setRatio() {
      if (this.tableData.length) {
        this.ratioOptions = this.tableData[0].ratios;
        this.ratio = this.tableData[0].ratios[0].name;
      }
      if (this.dataSource !== 1) {
        this.tableData = this.originData.filter(
          (item) => ![1, 2, 3, 4, 8].includes(item.subjectId)
        );
      }
      this.columns = [
        {
          title: "学科",
          dataIndex: "subjectName",
          align: "center",
          width: 75,
        },
        {
          title: "统计人数",
          dataIndex: "statTotal",
          align: "center",
          width: 100,
        },
        ...this.ratioOptions.map((item, index) => {
          let children = [
            {
              title: "本班",
              dataIndex: "proportion" + index,
              align: "center",
              width: 100,
              customRender: (text, record) => {
                const data =
                  record.ratios[index][
                    "proportion" + (this.dataSource == 1 ? "Ori" : "")
                  ];
                return data !== null ? data + "%" : "-";
              },
            },
            ...(this.$parent.topData.isSchoolDisplayReportClassRank == 1
              ? [
                  {
                    title: "校排名",
                    dataIndex: "rank" + index,
                    align: "center",
                    width: 85,
                    customRender: (text, record) =>
                      record.ratios[index][
                        "rank" + (this.dataSource == 1 ? "Ori" : "")
                      ],
                  },
                ]
              : []),
            {
              title: this.$route.query.examType == 1 ? "学校" : "年级",
              dataIndex: "gradeProportion" + index,
              align: "center",
              width: 100,
              customRender: (text, record) => {
                const data =
                  record.ratios[index][
                    "gradeProportion" + (this.dataSource == 1 ? "Ori" : "")
                  ];
                return data !== null ? data + "%" : "-";
              },
            },
            {
              title: this.$route.query.examType == 1 ? "高位班级" : "第一名",
              dataIndex: "no1Proportion" + index,
              align: "center",
              customRender: (text, record) => {
                const data =
                  record.ratios[index][
                    "no1Proportion" + (this.dataSource == 1 ? "Ori" : "")
                  ];
                return data !== null ? data + "%" : "-";
              },
            },
            {
              title: this.$route.query.examType == 1 ? "上位班级" : "上一名",
              dataIndex: "previousProportion" + index,
              align: "center",
              customRender: (text, record) => {
                const data =
                  record.ratios[index][
                    "previousProportion" + (this.dataSource == 1 ? "Ori" : "")
                  ];
                return data !== null ? data + "%" : "-";
              },
            },

            {
              title: this.$route.query.examType == 1 ? "下位班级" : "下一名",
              dataIndex: "nextProportion" + index,
              align: "center",
              customRender: (text, record) => {
                const data =
                  record.ratios[index][
                    "nextProportion" + (this.dataSource == 1 ? "Ori" : "")
                  ];
                return data !== null ? data + "%" : "-";
              },
            },
          ];
          if (
            this.$route.query.examType == 1 &&
            this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1
          ) {
            children.push({
              title: "整体",
              dataIndex: "unionTotal" + index,
              align: "center",
              width: 100,
              customRender: (text, record) => {
                const result =
                  record.ratios[index][
                    "unionProportion" + (this.dataSource == 1 ? "Ori" : "")
                  ];
                return result ? result + "%" : "";
              },
            });
          }

          return {
            title: item.name,
            key: "ratios" + index,
            align: "center",
            width: 100,
            children: [...children],
          };
        }),
      ];
      this.setRatioChart();
    },
    setTop() {
      let topSections = [];
      if (this.dataSource == 1) {
        this.tableData = this.originData.filter((item) => item.subjectId != 0);
      } else {
        this.tableData = this.originData.filter(
          (item) => ![0, 1, 2, 3, 4, 8].includes(item.subjectId)
        );
      }
      if (this.tableData.length) {
        this.ratioOptions = this.originData[1].topSections;
        this.ratio = this.originData[1].topSections[0].name;
      }
      this.columns = [
        {
          title: "学科",
          dataIndex: "subjectName",
          align: "center",
          width: 75,
        },
        {
          title: "统计人数",
          dataIndex: "statTotal",
          align: "center",
          width: 100,
        },
        ...this.ratioOptions.map((item, index) => {
          let children = [];
          if (this.$route.query.examType == 1) {
            children = [
              {
                title: "本班",
                dataIndex: "proportion" + index,
                align: "center",
                customRender: (text, record) => {
                  if (record.topSections && record.topSections.length) {
                    return (
                      (record.topSections[index][
                        "proportion" + (this.dataSource == 1 ? "Ori" : "")
                      ] || 0) + "%"
                    );
                  }
                  return "";
                },
              },
              {
                title: "校排名",
                dataIndex: "rank" + index,
                align: "center",
                customRender: (text, record) => {
                  if (record.topSections && record.topSections.length) {
                    return record.topSections[index]["rank"];
                  }
                  return "";
                },
              },
              {
                title: "学校",
                dataIndex: "gradeProportion" + index,
                align: "center",
                customRender: (text, record) => {
                  if (record.topSections && record.topSections.length) {
                    return (
                      (record.topSections[index][
                        "gradeProportion" + (this.dataSource == 1 ? "Ori" : "")
                      ] || 0) + "%"
                    );
                  }
                  return "";
                },
              },
              {
                title: "整体",
                dataIndex: "unionProportion" + index,
                align: "center",
                customRender: (text, record) => {
                  if (record.topSections && record.topSections.length) {
                    return (
                      (record.topSections[index][
                        "unionProportion" + (this.dataSource == 1 ? "Ori" : "")
                      ] || 0) + "%"
                    );
                  }
                  return "";
                },
              },
              {
                title: "高位班级",
                dataIndex: "no1Proportion" + index,
                align: "center",
                customRender: (text, record) => {
                  if (record.topSections && record.topSections.length) {
                    return (
                      (record.topSections[index][
                        "no1Proportion" + (this.dataSource == 1 ? "Ori" : "")
                      ] || 0) + "%"
                    );
                  }
                  return "";
                },
              },
              {
                title: "上位班级",
                dataIndex: "previousProportion" + index,
                align: "center",
                customRender: (text, record) => {
                  if (record.topSections && record.topSections.length) {
                    const data =
                      record.topSections[index][
                        "previousProportion" +
                          (this.dataSource == 1 ? "Ori" : "")
                      ];
                    if (data === null) {
                      return "-";
                    }
                    return data + "%";
                  }
                  return "";
                },
              },
              {
                title: "下位班级",
                dataIndex: "nextProportion" + index,
                align: "center",
                customRender: (text, record) => {
                  if (record.topSections && record.topSections.length) {
                    const data =
                      record.topSections[index][
                        "nextProportion" + (this.dataSource == 1 ? "Ori" : "")
                      ];
                    if (data === null) {
                      return "-";
                    }
                    return data + "%";
                  }
                  return "";
                },
              },
            ];
            if (
              this.$parent.topData.isSchoolDisplayReportUnionAverageScore != 1
            ) {
              children = children.filter((item) => item.title != "整体");
            }
            if (this.$parent.topData.isSchoolDisplayReportClassRank != 1) {
              children = children.filter((item) => item.title != "校排名");
            }
          } else {
            children = [
              {
                title: "年级",
                dataIndex: "gradeProportion" + index,
                align: "center",
                customRender: (text, record) => {
                  return (
                    (record.topSections[index][
                      "gradeProportion" + (this.dataSource == 1 ? "Ori" : "")
                    ] || 0) + "%"
                  );
                },
              },
              {
                title: "上一名",
                dataIndex: "previousProportion" + index,
                align: "center",
                customRender: (text, record) => {
                  return (
                    (record.topSections[index][
                      "previousProportion" + (this.dataSource == 1 ? "Ori" : "")
                    ] || 0) + "%"
                  );
                },
              },
              {
                title: "本班",
                dataIndex: "proportion" + index,
                align: "center",
                customRender: (text, record) => {
                  return (
                    (record.topSections[index][
                      "proportion" + (this.dataSource == 1 ? "Ori" : "")
                    ] || 0) + "%"
                  );
                },
              },
              {
                title: "下一名",
                dataIndex: "nextProportion" + index,
                align: "center",
                customRender: (text, record) => {
                  return (
                    (record.topSections[index][
                      "nextProportion" + (this.dataSource == 1 ? "Ori" : "")
                    ] || 0) + "%"
                  );
                },
              },
            ];
          }
          return {
            title: item.name,
            key: "topSection" + index,
            align: "center",
            children: [...children],
          };
        }),
      ];
      if (topSections.length) {
        this.defineOptions = {
          xAxis: {
            type: "category",
            axisTick: {
              show: false,
            },
            data: [...this.tableData.map((item) => item.subjectName)],
          },
          legend: {
            itemGap: 18,
            type: "scroll",
          },
          color: [],
        };
      }
      this.chartKey = Math.random();
      this.setTopSectionChart();
    },
    getField(preix, key) {
      if (preix) {
        if (key == "unionOverAverageRate") {
          return (
            preix + "OverAverageRateUnion" + (this.dataSource == 1 ? "Ori" : "")
          );
        }
        return (
          preix +
          this.capitalizeFirstLetter(key) +
          (this.dataSource == 1 ? "Ori" : "")
        );
      }
      return key + (this.dataSource == 1 ? "Ori" : "");
    },
    setMore() {
      let item = this.selectOptions.find((item) => item.value == this.params);
      let subject = this.originData.find(
        (item) => item.subjectId === this.subjectId
      );
      let xAxis;
      if (this.$route.query.examType == 1) {
        xAxis = ["高位班级", "上位班级", "本班", "下位班级"];
        this.columns = this.unionCols;
        this.tableData = this.moreDataModel.map((item) => {
          if (item.preix) {
            return {
              classNum: item.classNum,
              statTotal: subject[item.preix + "StatTotal"],
              highestScore: subject[this.getField(item.preix, "highestScore")],
              lowestScore: subject[this.getField(item.preix, "lowestScore")],
              rangeScore: subject[this.getField(item.preix, "rangeScore")],
              median: subject[this.getField(item.preix, "median")],
              unionOverAverageRate:
                subject[this.getField(item.preix, "unionOverAverageRate")],
              overAverageRate:
                subject[this.getField(item.preix, "overAverageRate")],
              standardDeviation:
                subject[this.getField(item.preix, "standardDeviation")],
              coefficientOfVariation:
                subject[this.getField(item.preix, "coefficientOfVariation")],
            };
          } else {
            return {
              classNum: item.classNum,
              statTotal: subject["statTotal"],
              highestScore: subject[this.getField(null, "highestScore")],
              lowestScore: subject[this.getField(null, "lowestScore")],
              rangeScore: subject[this.getField(null, "rangeScore")],
              median: subject[this.getField(null, "median")],
              unionOverAverageRate: subject["overAverageRate"],
              overAverageRate: subject[this.getField(null, "overAverageRate")],
              standardDeviation:
                subject[this.getField(null, "standardDeviation")],
              coefficientOfVariation:
                subject[this.getField(null, "coefficientOfVariation")],
            };
          }
        });
        if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore != 1) {
          this.columns = this.unionCols.filter((item) => {
            if (item.title) {
              if (item.title.indexOf("整体") == -1) {
                return item;
              }
            } else {
              return item;
            }
          });
          this.tableData = this.tableData.filter(
            (item) => item.classNum != "整体"
          );
        }
      } else {
        xAxis = [subject.subjectName];
        this.columns = this.moreCols;
      }
      this.defineOptions = {
        xAxis: {
          axisTick: {
            show: false,
          },
          type: "category",
          data: xAxis,
        },
      };
      if (this.$route.query.examType != 1) {
        if (this.subjectId === 0) {
          this.series = [
            {
              type: "bar",
              name: `班级${item.label}`,
              color: "#619AF1",
              barMaxWidth: 40,

              label: {
                position: "top",
                show: true,
                color: "inherit",
              },

              data: [
                subject[this.params + (this.dataSource == 1 ? "Ori" : "")],
              ],
            },
            {
              type: "line",
              name: `年级${item.label}`,
              smooth: true,
              color: "#FF9268",
              data: [
                subject[
                  "grade" +
                    this.capitalizeFirstLetter(this.params) +
                    (this.dataSource == 1 ? "Ori" : "")
                ],
              ],
            },
          ];
        } else {
          this.series = [
            {
              type: "bar",
              name: `班级${item.label}`,
              color: "#619AF1",
              barMaxWidth: 40,

              label: {
                position: "top",
                show: true,
                color: "inherit",
              },

              data: [
                subject[this.params + (this.dataSource == 1 ? "Ori" : "")],
              ],
            },
            {
              type: "line",
              name: `年级${item.label}`,
              smooth: true,
              color: "#FF9268",
              data: [
                subject[
                  "grade" +
                    this.titleCase(this.params) +
                    (this.dataSource == 1 ? "Ori" : "")
                ],
              ],
            },
          ];
        }
      } else {
        let key =
          this.capitalizeFirstLetter(this.params) +
          (this.dataSource == 1 ? "Ori" : "");
        this.series = [
          {
            type: "bar",
            name: `班级`,
            color: "#619AF1",
            barMaxWidth: 40,

            label: {
              position: "top",
              show: true,
              color: "inherit",
            },

            data: [
              subject["no1" + key],
              subject["previous" + key],
              subject[this.params + (this.dataSource == 1 ? "Ori" : "")],
              subject["next" + key],
            ],
          },
          {
            type: "line",
            name: `学校`,
            smooth: true,
            color: "#FF9268",
            data: [
              subject["grade" + key],
              subject["grade" + key],
              subject["grade" + key],
              subject["grade" + key],
            ],
          },
        ];
        if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          this.series.push({
            type: "line",
            name: `整体`,
            color: "#F65252",
            smooth: true,
            data: [
              subject["union" + key],
              subject["union" + key],
              subject["union" + key],
              subject["union" + key],
            ],
          });
        }
      }
      this.chartKey = Math.random();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";

.operate {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dataSource {
    margin-bottom: 18px;
  }

  .subjects {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    .subject {
      height: 32px;
      border-radius: 4px;
      border: 1px solid #d5d6db;
      line-height: 32px;
      box-sizing: border-box;
      padding: 0 18px;
      margin-right: 8px;
      margin-bottom: 18px;
      color: #0a1119ff;
      font-size: 14px;
      cursor: pointer;
    }

    .subject-active {
      background: #7ab5efff;
      color: #fff;
    }
  }
}
</style>
