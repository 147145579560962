<template>
  <div ref="branch" v-lazyChart="{ fn: getCondition }" class="container">
    <div class="title">成绩分布</div>
    <div class="group">
      <a-select
        v-model="category"
        not-found-content="暂无数据"
        style="width: 120px"
        @change="categoryChange"
      >
        <a-select-option
          v-for="(item, index) in cateOptions"
          :key="index"
          :value="item.id"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
      <div
        v-for="(item, index) in groupList"
        :key="index"
        :class="['btn', item.id === groupId ? 'btn-active' : '']"
        @click="groupChange(item.id)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="sub-title">成绩分布</div>
    <div class="operate">
      数据源：
      <a-radio-group v-model="search.dataSource" @change="dataSourceChange">
        <a-radio :value="1"> 原始分</a-radio>
        <a-radio :value="2"> 赋分 </a-radio>
      </a-radio-group>
      <div v-if="type == 1" class="scoreSegmentation">
        分数段区间：
        <span>上限：</span>
        <a-input v-model="search.high" style="width: 64px" />
        <span>分</span>
        <span>下限：</span>
        <a-input v-model="search.low" style="width: 64px" />
        <span>分</span>
        <span>间隔：</span>
        <a-input v-model="search.interval" style="width: 64px" />
        <span>分</span>
        <a-button type="primary" @click="getData">确定</a-button>
      </div>
      <div
        v-if="type == 2 && $route.query.examType != 1"
        class="scoreSegmentation"
      >
        排名区间：
        <a-input v-model="search.low" style="width: 64px" />
        <span>名</span>
        <span>-</span>
        <a-input v-model="search.high" style="width: 64px" />
        <span>名</span>

        <span>间隔：</span>
        <a-input v-model="search.interval" style="width: 64px" />
        <span>名</span>
        <a-button type="primary" @click="getBranchRank">确定</a-button>
      </div>
    </div>
    <div v-if="$route.query.examType != 1" class="typeChange">
      <a-radio-group v-model="type" button-style="solid" @change="typeChange">
        <a-radio-button :value="1"> 成绩分布 </a-radio-button>
        <a-radio-button :value="2"> 排名分布</a-radio-button>
      </a-radio-group>
    </div>
    <div class="charts">
      <chart
        v-if="chartData.length"
        :key="tokenKey + 'chart'"
        :series="chartSeries"
        :define-options="defineOptions"
        :data-zoom="20"
      />
      <no-data v-else />
    </div>
    <div class="tip" style="margin-bottom: 18px">
      <div class="intent">注：</div>
      <div class="text">
        <div>
          以上数据结果均以{{
            search.dataSource === 1 ? "原始分" : "赋分"
          }}进行统计运算。
        </div>
        <div>红色柱表示校平均分所在分数段。</div>
      </div>
    </div>
    <classCompetitiveVue
      v-if="groupId !== null"
      :key="'classCompetitiveVue' + classNum + groupId"
      :class-num="classNum"
      :top-data="topData"
      :eleccomb-id="groupId"
    />
  </div>
</template>

<script>
import chart from "../../components/charts.vue";
import {
  getstateleccateoption,
  getstateleccomboptionV2,
} from "@/core/api/academic/common";
import {
  getbanzhurenreportscoredistribution,
  getbanzhurenreportscoredistributionrank,
} from "@/core/api/newExamAcademic/director";
import classCompetitiveVue from "../classCompetitiveness/index.vue";
import { brachData } from "@/core/api/newExamAcademic/union/head";
export default {
  name: "",
  components: {
    chart,
    classCompetitiveVue,
  },
  props: {
    classNum: {
      type: String,
      default: "",
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tokenKey: Math.random(),
      chartSeries: [],
      category: 1,
      groupId: null,
      search: { dataSource: 1, high: 0, low: 0, interval: 0 },
      groupList: [],
      chartData: [],
      defineOptions: null,
      type: 1,
      cateOptions: [],
    };
  },
  mounted() {
    // this.typeChange();
  },
  methods: {
    groupChange(id) {
      this.groupId = id;
      this.chartData = [];
      if (this.type === 1) {
        this.getData();
      } else {
        this.getBranchRank();
      }
    },
    isNumber() {
      return (
        /^[0-9]*$/.test(this.search.high) &&
        /^[0-9]*$/.test(this.search.low) &&
        /^[0-9]*$/.test(this.search.interval)
      );
    },
    async getCondition(loadingInstance, io) {
      if (this.$parent.topData.hasPhysics == 1) {
        this.category = 1;
      } else if (this.$parent.topData.hasHistory == 1) {
        this.category = 2;
      }
      this.$forceUpdate();
      this.getCate(loadingInstance, io);
    },
    async getCate(loadingInstance, io) {
      const res = await getstateleccateoption({
        id: this.$route.query.id,
        classNum: this.classNum,
        hasPhysicsCate: this.$parent.topData.hasPhysics,
        hasHistoryCate: this.$parent.topData.hasHistory,
        viewSchoolId: this.$route.query.schoolId,
      }).catch(() => {
        this.closeLoading(loadingInstance, io);
      });

      this.cateOptions = res.data.data;
      if (res.data.data.length) {
        this.category = res.data.data[0].id;
        this.getGroupList();
      }
      this.closeLoading(loadingInstance, io);
    },
    async getGroupList() {
      const res = await getstateleccomboptionV2({
        id: this.$route.query.id,
        eleccateId: this.category,
        classNum: this.classNum,
        hasCate: 1,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.groupList = res.data.data;
      if (this.groupList.length) {
        this.groupId = this.groupList[0].id;
        this.typeChange();
      }
    },
    typeChange() {
      if (this.type == 1) {
        this.search.high = this.$parent.topData.segmentMaximum;
        this.search.low = this.$parent.topData.segmentMinimum;
        this.search.interval = this.$parent.topData.segmentGap;
        this.getData();
      } else {
        let { rankMinimum, rankMaximum, rankGap } = this.$parent.topData;
        this.search.high = rankMaximum;
        this.search.low = rankMinimum;
        this.search.interval = rankGap;
        this.getBranchRank();
      }
    },
    async getData() {
      const result = this.isNumber();
      if (!result) {
        this.$message({
          type: "warning",
          message: "请输入正整数",
          showClose: true,
        });
        return false;
      }
      let res;
      if (this.$route.query.examType == 1) {
        res = await brachData({
          ...this.search,
          id: this.$route.query.id,
          classNum: this.classNum,
          eleccombId: this.groupId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getbanzhurenreportscoredistribution({
          ...this.search,
          id: this.$route.query.id,
          classNum: this.classNum,
          eleccombId: this.groupId,
        });
      }
      this.chartData = res.data.data;
      this.setChart();
    },
    async getBranchRank() {
      const result = this.isNumber();
      if (!result) {
        this.$message({
          type: "warning",
          message: "请输入正整数",
          showClose: true,
        });
        return false;
      }
      const res = await getbanzhurenreportscoredistributionrank({
        ...this.search,
        id: this.$route.query.id,
        classNum: this.classNum,
        eleccombId: this.groupId,
      });
      this.chartData = res.data.data;
      this.setChart();
    },
    setChart() {
      this.defineOptions = {
        grid: {
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [
            ...this.chartData.map(
              (item) => item[this.type == 1 ? "segment" : "name"]
            ),
          ],
          axisLabel: {
            rotate: "45",
          },
          axisTick: {
            show: false,
          },
        },
      };
      if (this.type === 1) {
        this.chartSeries = [
          {
            data: [
              ...this.chartData.map((item) => {
                if (item["isGradeAverage"] == 1) {
                  return {
                    value:
                      item[
                        this.$route.query.examType == 1 ? "proportion" : "total"
                      ] || 0,
                    itemStyle: {
                      color: "#F56C6B",
                    },
                  };
                }
                return (
                  item[
                    this.$route.query.examType == 1 ? "proportion" : "total"
                  ] || 0
                );
              }),
            ],

            name: this.$route.query.examType == 1 ? "占比" : "人数",
            type: "bar",
            barMaxWidth: 40,

            label: {
              position: "top",
              color: "inherit",
              show: true,
              formatter: ({ value }) => (value !== null ? value + "%" : null),
            },
          },
        ];
        this.tokenKey = Math.random();
      } else {
        this.chartSeries = [
          {
            data: [
              ...this.chartData.map((item) => {
                if (item["isGradeAverage"] == 1) {
                  return {
                    value: item["total"] || 0,
                    itemStyle: {
                      color: "#F56C6B",
                    },
                  };
                }
                return item["total"] || 0;
              }),
            ],
            type: "bar",
            barMaxWidth: 40,
            name: "人数",

            label: {
              position: "top",
              color: "inherit",
              show: true,
              formatter: ({ value }) => value,
            },
          },
        ];
        this.tokenKey = Math.random();
      }
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.branch);
        });
      }
    },
    categoryChange() {
      this.getGroupList();
    },
    dataSourceChange() {
      this.typeChange();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";
.group {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  box-sizing: border-box;
  padding: 18px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #d5d6db;
  .btn {
    width: 82px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #d5d6db;
    background-color: #fff;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    color: #0a1119;
    font-size: 14px;
    margin-left: 8px;
  }
  .btn-active {
    color: #ffffff;
    background: #2474ed;
  }
}
.operate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
  .scoreSegmentation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 40px;
    span {
      margin: 0 8px;
    }
  }
}
.typeChange {
  display: flex;
  justify-content: flex-end;
}
</style>
