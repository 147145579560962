<template>
  <div ref="bar" :style="{ width: '100%', height: height + 'px' }"></div>
</template>

<script>
import * as echarts from "echarts";
import * as ecStat from "echarts-stat";
export default {
  name: "Bar",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    height: {
      type: Number,
      default: 500,
    },
    isEcStat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: { itemGap: 30 },
        grid: {
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
            },
            axisTick: { show: false },
            splitLine: { show: false },
          },
        ],
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
        series: [],
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  methods: {
    initChart() {
      if (this.isEcStat) {
        echarts.registerTransform(ecStat.transform.regression);
      }
      this.$refs.bar.setAttribute("_echarts_instance_", "");
      let charts = echarts.init(this.$refs.bar, null, {
        devicePixelRatio: 2.5,
      });
      this.option = { ...this.data };
      charts.setOption(this.option, true);
      setTimeout(() => {
        window.onresize = () => {
          charts.resize();
        };
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped></style>
