<template>
  <div class="stat-contain">
    <div class="topData">
      <div class="school-tag">{{ schoolName }}</div>
      <div class="title">
        <div class="new-exam-tag">新高考</div>
        <div class="text">{{ topData.name }}</div>
        <div :class="['sub-tag', 'general']">
          {{ getExamType(topData.type) }}
        </div>
      </div>
      <div class="sub">
        <div class="back" @click="back"><a-icon type="left" /> 返回</div>
        <div class="date">
          <span>年级：{{ topData.year }}级</span>
          <span>考试时间：{{ topData.examDate }}</span>
        </div>
        <div></div>
      </div>
      <exportBtn
        :request-func="requestFunc"
        :params="{ id: $route.query.id, type: 0, classNum: classNum }"
        btn-type="primary"
        text="导出全部报表"
      >
        <div class="model-title">班主任报告</div>
      </exportBtn>
      <div class="a-tabs">
        <a-tabs v-model="classNum" @change="tabChange">
          <a-tab-pane
            v-for="item in classNumList"
            :key="item.name"
            :tab="item.name"
          >
          </a-tab-pane>
        </a-tabs>
      </div>
      <div
        class="dashboard"
        :style="{
          width: topData.hasPhysics && topData.hasHistory ? '100%' : '85%',
        }"
      >
        <template v-if="topData.hasPhysics == 1">
          <div class="vertical-text normal">物 理 类</div>
          <div :class="['box', 'normal']">
            <div class="item">
              <div class="box-title">
                <span>{{ topData.physicsEleccombCount || 0 }}</span
                >个
              </div>
              <div>组合</div>
            </div>
            <div class="item">
              <div class="box-title">
                <span>{{ topData.physicsStudentCount || 0 }}</span
                >名
              </div>
              <div>参考考生</div>
            </div>
          </div>
          <div
            :class="[
              'box',
              'normal',
              topData.hasPhysics == 1 ? 'flex-box' : '',
            ]"
          >
            <div class="box-wrapper dashed">
              <div class="flex">
                <div class="item">
                  <div class="box-title">
                    <span>{{ topData.physicsHighestScoreOri || 0 }}</span>
                  </div>
                  <div>最高分</div>
                </div>
                <div class="item">
                  <div class="box-title">
                    <span>{{ topData.physicsLowestScoreOri || 0 }}</span>
                  </div>
                  <div>最低分</div>
                </div>
              </div>
              <div class="wrapper-title">原始分</div>
            </div>
            <div class="box-wrapper dashed">
              <div class="flex">
                <div class="item">
                  <div class="box-title">
                    <span>{{ topData.physicsHighestScore || 0 }}</span>
                  </div>
                  <div>最高分</div>
                </div>
                <div class="item">
                  <div class="box-title">
                    <span>{{ topData.physicsLowestScore || 0 }}</span>
                  </div>
                  <div>最低分</div>
                </div>
              </div>
              <div class="wrapper-title">赋分</div>
            </div>
            <div v-if="showRank(topData)" class="box-wrapper dashed">
              <div class="flex">
                <div class="item dashed">
                  <div class="box-title">
                    <span>{{ topData.physicsAverageScoreRank || 0 }}</span>
                  </div>
                  <div>
                    {{
                      $route.query.examType == 1 ? "均分(校排名)" : "均分排名"
                    }}
                  </div>
                </div>
                <div v-if="$route.query.examType == 1" class="item dashed">
                  <div class="box-title">
                    <span>{{ topData.physicsAverageScoreUnionRank || 0 }}</span>
                  </div>
                  <div>均分(整体排名)</div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="topData.hasHistory == 1">
          <div class="vertical-text warning">历 史 类</div>
          <div :class="['box', 'warning']">
            <div class="item">
              <div class="box-title">
                <span>{{ topData.historyEleccombCount || 0 }}</span
                >个
              </div>
              <div>组合</div>
            </div>
            <div class="item">
              <div class="box-title">
                <span>{{ topData.historyStudentCount || 0 }}</span
                >名
              </div>
              <div>参考考生</div>
            </div>
          </div>
          <div
            :class="[
              'box',
              'warning',
              topData.hasHistory == 1 ? 'flex-box' : '',
            ]"
          >
            <div class="box-wrapper dashed">
              <div class="flex">
                <div class="item">
                  <div class="box-title">
                    <span>{{ topData.historyHighestScoreOri || 0 }}</span>
                  </div>
                  <div>最高分</div>
                </div>
                <div class="item">
                  <div class="box-title">
                    <span>{{ topData.historyLowestScoreOri || 0 }}</span>
                  </div>
                  <div>最低分</div>
                </div>
              </div>
              <div class="wrapper-title">原始分</div>
            </div>
            <div class="box-wrapper dashed">
              <div class="flex">
                <div class="item">
                  <div class="box-title">
                    <span>{{ topData.historyHighestScore || 0 }}</span>
                  </div>
                  <div>最高分</div>
                </div>
                <div class="item">
                  <div class="box-title">
                    <span>{{ topData.historyLowestScore || 0 }}</span>
                  </div>
                  <div>最低分</div>
                </div>
              </div>
              <div class="wrapper-title">赋分</div>
            </div>
            <div v-if="showRank(topData)" class="box-wrapper dashed">
              <div class="flex">
                <div class="item dashed">
                  <div class="box-title">
                    <span>{{ topData.historyAverageScoreRank || 0 }}</span>
                  </div>
                  <div>
                    {{
                      $route.query.examType == 1 ? "均分(校排名)" : "均分排名"
                    }}
                  </div>
                </div>
                <div v-if="$route.query.examType == 1" class="item">
                  <div class="box-title">
                    <span>{{ topData.historyAverageScoreUnionRank || 0 }}</span>
                  </div>
                  <div>均分(整体排名)</div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <template v-if="classNum !== null && topData.year">
      <branch
        id="branch"
        :key="'branch' + classNum"
        :class-num="classNum"
        :top-data="topData"
      />

      <student id="student" :key="'student' + classNum" :class-num="classNum" />
      <development
        id="development"
        :key="'development' + classNum"
        :class-num="classNum"
      />
      <paper
        id="paper"
        :key="'paper' + classNum"
        :class-num="classNum"
        :top-data="topData"
      />
    </template>
    <pageNav :menu-list="menuList" />
  </div>
</template>

<script>
import { examTypeOptions } from "@/core/util/constdata";
import branch from "./branch/index.vue";
import student from "./student";
import development from "./development";
import paper from "./paper";
import { getTopData } from "@/core/api/newExamAcademic/director";
import { getTopInfo } from "@/core/api/newExamAcademic/union/head";
import { getstatclassoption } from "@/core/api/academic/common";
import { getStore } from "@/core/util/store";
import pageNav from "@/views/academic/components/pageNav.vue";
import { exportHeadReportNew } from "@/core/api/academic/headReport";
import exportBtn from "@/views/academic/components/exportBtn.vue";
export default {
  name: "",
  components: {
    branch,
    student,
    development,
    paper,
    pageNav,
    exportBtn,
  },
  data() {
    return {
      requestFunc: exportHeadReportNew,
      classNum: null,
      topData: {
        type: 99,
      },
      classNumList: [],
      schoolName: "",
      menuList: [
        {
          id: "branch",
          name: "成绩分布",
        },
        {
          id: "student",
          name: "重点关注学生",
        },
        {
          id: "development",
          name: "发展性分析",
        },
        {
          id: "paper",
          name: "试卷分析",
        },
      ],
      subjectIds: [],
    };
  },
  mounted() {
    this.getClassList();
    this.schoolName = getStore({ name: "userInfo" }).schoolName;
    if (this.$route.query.examType == 1) {
      this.schoolName = this.$route.query.schoolName;
    } else {
      this.schoolName = getStore({ name: "userInfo" }).schoolName;
    }
  },
  methods: {
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value === type);
        return data.label;
      }
    },
    tabChange() {
      this.topData = {};
      this.getData();
    },
    async getClassList() {
      const res = await getstatclassoption({
        id: this.$route.query.id,
        requestPage: 3,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.classNumList = res.data.data;
      this.classNum = res.data.data[0].name;
      this.getData();
    },
    showRank(topData) {
      if (this.$route.query.examType == 1) {
        return topData.isSchoolDisplayReportClassRank == 1;
      }
      return true;
    },
    async getData() {
      let res;
      if (this.$route.query.examType == 1) {
        res = await getTopInfo({
          id: this.$route.query.id,
          classNum: this.classNum,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getTopData({
          id: this.$route.query.id,
          classNum: this.classNum,
        });
      }

      this.topData = res.data.data;
      this.subjectIds = res.data.data.subjectKnowledges.map(
        (item) => item.subjectId
      );
    },
    back() {
      this.$router.go(-1);
    },
    handleClick(item) {
      this.classNum = item.id;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../new-exam.module.scss";

.classNums {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  .classNum {
    box-sizing: border-box;
    padding: 6px 18px;
    background: transparent;
    cursor: pointer;
    color: #0a1119;
    border-radius: 16px;
    border: 1px solid transparent;
    margin-bottom: 18px;
  }

  .classNum + .classNum {
    margin-left: 18px;
  }

  .classNum-active {
    color: #2474ed;
    border-color: #2474ed;
    background-color: #f3f8ff;
  }
}

.vertical-text {
  writing-mode: vertical-lr;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  width: 38px !important;
  border-radius: 8px;
  height: 88px;
}

.flex {
  display: flex;
  align-items: center;
}

.box {
  user-select: none;
  color: #3e4551;
  text-align: center;
  font-size: 12px;
  box-sizing: border-box;
  padding: 0 !important;
  width: fit-content !important;

  .box-wrapper {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .wrapper-title {
      font-size: 14px;
    }

    .item {
      margin: 0 4px !important;
    }
  }

  .dashed + .dashed {
    border-left: 1px dashed #d2e2f9;
  }

  .item {
    width: 52px;
    margin: 18px 4px !important;
  }

  .item + .item {
    margin-left: 4px !important;
  }

  .category-title {
    margin-top: 4px;
    font-size: 14px;
  }
}

.normal {
  background-color: #f3f8ff;

  .box-title {
    color: #2474ed;

    span {
      font-size: 14px;
    }
  }
}

.warning {
  background-color: #fff8f3;

  .box-title {
    color: #ff7a05;

    span {
      font-size: 14px;
    }
  }
}

.vertical-text + .box,
.box + .box {
  margin-left: 4px !important;
}

.box + .vertical-text {
  margin-left: 8px !important;
}

.a-tabs {
  width: 100%;
}

::v-deep .ant-tabs-ink-bar {
  display: none !important;
}

::v-deep .ant-tabs-bar {
  border-bottom: none;
}

::v-deep .ant-tabs-tab {
  height: 32px;
  line-height: 32px;
  padding: 0 16px;
}

::v-deep .ant-tabs-tab-active {
  border: 1px solid #2474ed;
  text-shadow: 0 0 0.25px currentColor;
  border-radius: 16px;
  color: #2474ed;
  background: #f3f8ff;
}

::v-deep .ant-tabs-nav-wrap {
  margin-bottom: 0;
}

.flex-box {
  flex: 1;

  .box-wrapper {
    flex: 1;
    align-items: center;

    .flex {
      width: 100%;
      justify-content: center;

      .item {
        flex: 1;
      }
    }
  }
}
</style>
