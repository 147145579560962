import request from "@/core/services/axios";
export const getTopInfo = (params) => {
  return request({
    url: "/report/statunionnewgaokaobanzhurenreport/getbanzhurenreporttopinfo",
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const brachData = (params) => {
  //班主任报告-成绩分布-分数分布-联考新高考
  return request({
    url: "/report/statunionnewgaokaobanzhurenreport/getbanzhurenreportscoredistribution",
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const classcompetitive = (params) => {
  //班主任报告-班级竞争力-联考新高考
  return request({
    url: "/report/statunionnewgaokaobanzhurenreport/getbzrreportclasscompetitive",
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const waveData = (params) => {
  // 主任报告-重点关注学生-波动生
  return request({
    url: "/report/statunionnewgaokaobanzhurenreport/getbzrreportimportstudent/wave",
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionToporbackward = (params) => {
  // 班主任报告-重点关注学生-拔尖生-后进生
  return request({
    url: "/report/statunionnewgaokaobanzhurenreport/getbzrreportimportstudent/toporbackward",
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionTrack = (params) => {
  // 班主任报告-重点关注学生-跟踪生
  return request({
    url: "/report/statunionnewgaokaobanzhurenreport/getbzrreportimportstudent/track",
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionGetbzrreportdevelopanalysis = (params) => {
  // 班主任报告-发展性分析
  return request({
    url: "/report/statunionnewgaokaobanzhurenreport/getbzrreportdevelopanalysis",
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const uniongetbzrreportpaperanalyQuestion = (params) => {
  // 班主任报告-试卷分析-应答情况分析-题目
  return request({
    url: "/report/statunionnewgaokaobanzhurenreport/getbzrreportpaperanaly/question",
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const uniongetbzrreportpaperanalyKnowledge = (params) => {
  // 班主任报告-试卷分析-应答情况分析-知识点
  return request({
    url: "/report/statunionnewgaokaobanzhurenreport/getbzrreportpaperanaly/knowledge",
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const uniongetbzrreportpaperanalyStudent = (params) => {
  // 班主任报告-试卷分析-学生应答反馈
  return request({
    url: "/report/statunionnewgaokaobanzhurenreport/getbzrreportpaperanaly/student",
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
