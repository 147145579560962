<template>
  <div ref="development" v-lazyChart="{ fn: getSujbectList }" class="container">
    <div class="title">发展性分析</div>
    <examTransformVue
      :stat-id="$route.query.id"
      :exam-arr="examArr"
      :class-num="classNum"
      new-exam
      :check-list="checkList"
      style="margin-bottom: 18px"
      @submit="getExamId"
    />
    <div class="btns">
      <div
        v-for="(item, index) in subjectList"
        :key="index"
        :class="['btns-item', subjectId === item.id ? 'btns-active' : '']"
        @click="subjectClick(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="operate">
      <div></div>
      <a-radio-group
        v-model="dataType"
        button-style="solid"
        @change="dataTypeChange"
      >
        <a-radio-button v-if="ratios.length" value="1"> 比率 </a-radio-button>
        <a-radio-button v-if="topSections.length" value="2">
          前N名
        </a-radio-button>
      </a-radio-group>
    </div>
    <div class="params">
      <a-select
        :key="dataType"
        v-model="params"
        not-found-content="暂无数据"
        style="width: 200px"
        @change="paramsChange"
      >
        <a-select-option
          v-for="(item, index) in options"
          :key="dataType + '' + index"
          :value="item.name"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
    </div>
    <chart
      v-if="defineOptions && series.length"
      :key="chartKey"
      :define-options="defineOptions"
      :series="series"
    />
    <no-data v-else />
    <div class="tip">
      <div class="intent">注：</div>
      <div>
        <div>折线图——班级历次考试指标值</div>
        <div>
          1.
          同色折线不同考试节点对比：最近一次考试与历次考试进行对比，用于查看班级考试指标的变化趋势。
        </div>
        <div>
          2.
          同一考试节点不同颜色值对比：每次考试各班级间指标的差距。可选择同比、环比及周期性对比的角度进行发展性的分析。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import examTransformVue from "../../components/examTransform.vue";
import { examTypeOptions } from "@/core/util/constdata";
import chart from "../../components/charts.vue";
import { getstatsubjectoption } from "@/core/api/academic/common";
import { getbzrreportdevelopanalysis } from "@/core/api/newExamAcademic/director";
import { unionGetbzrreportdevelopanalysis } from "@/core/api/newExamAcademic/union/head";

export default {
  name: "Development",
  components: {
    examTransformVue,
    chart,
  },
  props: {
    classNum: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      defineOptions: null,
      series: [],
      ratios: [],
      topSections: [],
      type: "1",
      examList: [],
      confirmLoading: false,
      examTypeOptions: examTypeOptions,
      exam: {
        type: "1",
      },
      category: 1,
      dataType: "1",
      compareIds: [],
      params: "",
      options: [],
      chartKey: Math.random(),
      subjectId: null,
      subjectList: [],
      examArr: [],
      subjectListOrigin: [],
      checkList: [],
    };
  },
  created() {},
  methods: {
    subjectClick({ id }) {
      this.subjectId = id;
      this.getData();
    },
    paramsChange() {
      if (this.dataType == 1) {
        this.setCharts("ratios");
      } else if (this.dataType == 2) {
        this.setCharts("topSections");
      }
    },
    async getSujbectList(loadingInstance, io) {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        classNum: this.classNum,
        hasTotal: 1,
        requestPage: 3,
        viewSchoolId: this.$route.query.schoolId,
      }).catch(() => this.closeLoading(loadingInstance, io));
      this.subjectListOrigin = res.data.data;
      this.subjectList = res.data.data;
      if (res.data.data.length) {
        this.subjectId = res.data.data[0].id;
        this.getData();
      }
      this.closeLoading(loadingInstance, io);
    },
    async getData() {
      let res;
      if (this.$route.query.examType == 1) {
        res = await unionGetbzrreportdevelopanalysis({
          id: this.$route.query.id,
          classNum: this.classNum,
          contrastStatId: this.compareIds ? this.compareIds.join(",") : "",
          isAllClass: 0,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getbzrreportdevelopanalysis({
          id: this.$route.query.id,
          classNum: this.classNum,
          contrastStatId: this.compareIds ? this.compareIds.join(",") : "",
          isAllClass: 0,
          subjectId: this.subjectId,
        });
      }

      this.examArr = res.data.data.stats;
      this.checkList = res.data.data.stats.map((item) => item.id);
      if (res.data.data.stats.length) {
        if (res.data.data.stats[0].classSubjects.length) {
          let subject = res.data.data.stats[0].classSubjects.find(
            (item) => item.subjectId === this.subjectId
          );
          if (this.subjectId === 0) {
            subject = res.data.data.stats[0].classData;
          }
          this.ratios = subject.ratios;
          let topSectionsSubject = res.data.data.stats[0].classSubjects.find(
            (item) => item.id !== 0
          );
          this.topSections = topSectionsSubject.topSections;
          this.dataTypeChange();
        }
      }
    },
    closeLoading(loadingInstance, io) {
      if ((loadingInstance, io)) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.development);
        });
      }
    },
    dataTypeChange() {
      this.defineOptions = null;
      this.series = [];
      if (this.examArr.length) {
        this.defineOptions = {
          legend: {
            itemGap: 20,
            type: "scroll",
            orient: "horizontal",
          },
          xAxis: {
            type: "category",
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              interval: 0,
              width: 100,
              overflow: "breakAll",
            },
            data: [...this.examArr.map((item) => item.name)],
          },
        };
        if (this.dataType == "1") {
          this.options = this.ratios;
          if (this.ratios.length) {
            this.params = this.ratios[0].name;
          }
          this.subjectList = this.subjectListOrigin;
          this.setCharts("ratios");
        } else if (this.dataType == "2") {
          this.subjectList = this.subjectListOrigin.filter(
            (item) => item.id !== 0
          );
          if (this.subjectId == 0) {
            this.subjectId = this.subjectList[0].id;
          }
          this.options = this.topSections;
          if (this.topSections.length) {
            this.params = this.topSections[0].name;
          }
          this.setCharts("topSections");
        }
      }
      this.chartKey = Math.random();
    },
    setCharts(feild) {
      if (this.examArr.length) {
        this.defineOptions = {
          legend: { itemGap: 18, type: "scroll" },
          grid: {
            containLabel: true,
          },
          xAxis: {
            type: "category",
            axisTick: {
              show: false,
            },
            data: [...this.examArr.map((item) => item.name + item.examDate)],
            axisLabel: {
              show: true,
              interval: 0,
              width: 100,
              overflow: "breakAll",
            },
          },
        };
        let obj = this.options.find((it) => it.name === this.params);
        let data = [];

        if (this.subjectId === 0) {
          data = [
            ...this.examArr.map((item) => {
              let itemObj = item.classData[feild].find(
                (it) => it.name === this.params
              );
              return itemObj.total;
            }),
          ];
        } else {
          data = [
            ...this.examArr.map((item) => {
              let subject = item.classSubjects.find(
                (item) => item.subjectId === this.subjectId
              );
              let itemObj = subject[feild].find(
                (it) => it.name === this.params
              );
              return itemObj.total;
            }),
          ];
        }
        this.series = [
          {
            type: "line",
            name: obj.name,
            data: [...data],
          },
        ];
      }
      this.chartKey = Math.random();
    },
    setTopSections() {},
    getExamId(val) {
      this.compareIds = val;
      this.getData();
    },
    getClassType() {
      if (this.subjectId > 3 || this.electiveId !== null) {
        return "教学";
      }
      return "行政";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";
.tabs {
  //   margin-bottom: 18px;
}
.compare {
  background: #f3f8ff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  color: #3e4551;
  font-size: 14px;
  margin-bottom: 18px;
}
.btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .btns-item {
    border-radius: 4px;
    border: 1px solid #d5d6db;
    box-sizing: border-box;
    padding: 6px 18px;
    color: #0a1119;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 18px;
    transition: all 0.2s ease-in-out;
    &:hover {
      border-color: #2474ed;
      background-color: #2474ed;
      color: #ffff;
    }
  }
  .btns-active {
    border-color: #2474ed;
    background-color: #2474ed;
    color: #ffff;
  }
  .btns-item + .btns-item {
    margin-left: 8px;
  }
}
.exam-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
  .filter-item + .filter-item {
    margin-left: 8px;
  }
}
::v-deep .ant-select-selection--single {
  height: 35px;
  line-height: 35px;
}
.operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
.subjectList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #d5d6db;
  box-sizing: border-box;
  padding: 16px 16px 0 16px;
  .subject {
    height: 32px;
    border-radius: 4px;
    border: 1px solid #d5d6db;
    line-height: 32px;
    color: #0a1119ff;
    font-size: 14px;
    letter-spacing: 1px;
    box-sizing: border-box;
    padding: 0 18px;
    margin-right: 8px;
    margin-bottom: 16px;
    cursor: pointer;
  }
  .subject-active {
    background-color: #2474edff;
    color: #ffffff;
  }
}
.params {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 18px;
}
</style>
