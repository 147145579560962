<template>
  <div ref="student" v-lazyChart="{ fn: getCondition }" class="container">
    <div class="title">重点关注学生</div>
    <div class="operate">
      <div>
        <a-select
          v-if="type === 1 && examList.length"
          v-model="compareStatId"
          not-found-content="暂无数据"
          style="width: 400px"
          @change="compareStatIdChange"
        >
          <a-select-option
            v-for="(item, index) in examList"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <a-radio-group v-model="type" button-style="solid" @change="typeChange">
        <a-radio-button :value="1"> 波动生 </a-radio-button>
        <a-radio-button :value="2"> 拔尖生 </a-radio-button>
        <a-radio-button :value="3"> 后进生 </a-radio-button>
        <a-radio-button :value="4"> 跟踪生 </a-radio-button>
      </a-radio-group>
    </div>

    <div class="charts">
      <exportBtn
        :request-func="requestFunc"
        align="left"
        style="margin-bottom: 18px"
        :params="{
          id: $route.query.id,
          type: 2,
          classNum: classNum,
          topOrBackward: topOrBackward,
          upCompareId: compareStatId,
        }"
      >
        <div v-if="type === 2" class="filter">
          校排名前：<a-input
            v-model="topOrBackward"
            oninput="value=value.replace(/[^\d]/g,'')"
            :max-length="5"
            style="width: 72px"
            @blur="topOrBackward = $event.target.value"
          ></a-input>
          <a-button
            type="primary"
            style="margin-left: 10px"
            @click="searchSubmit"
            >确定</a-button
          >
        </div>
        <div v-else-if="type === 3" class="filter">
          校排名后：<a-input
            v-model="topOrBackward"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            :max-length="5"
            @blur="topOrBackward = $event.target.value"
          ></a-input>
          <a-button
            type="primary"
            style="margin-left: 10px"
            @click="searchSubmit"
            >确定</a-button
          >
        </div>
        <a-radio-group
          v-if="type === 1"
          v-model="waveType"
          button-style="solid"
          @change="setWave"
        >
          <a-radio-button value="upStudents"> 上升波动 </a-radio-button>
          <a-radio-button value="downStudents"> 下降波动 </a-radio-button>
        </a-radio-group>
      </exportBtn>
      <!-- <chart :series="series" :define-options="defineOptions" /> -->
    </div>

    <scatter
      v-if="tableData.length && type === 1 && getShowScatter()"
      :key="scatterKey"
      is-ec-stat
      :data="defineOptions"
    />
    <template v-if="tableData.length">
      <a-table
        :key="tableReactiveKey"
        :columns="columns"
        :data-source="tableData"
        bordered
        :scroll="{ x: 'max-content' }"
        :pagination="false"
      >
        <template slot="name" slot-scope="text, record">
          <a-button type="link" @click="handleClick(record)">{{
            record.name
          }}</a-button>
        </template>
        <template slot="potentialSubject" slot-scope="text, record">
          <div
            v-if="record.littleSubject && record.littleSubject.length"
            class="potentialSubject"
          >
            <span>{{ record.littleSubject.join(",") }}</span
            >稍微偏科
          </div>
          <div
            v-if="record.seriousSubject && record.seriousSubject.length"
            class="potentialSubject"
          >
            <span>{{ record.seriousSubject.join(",") }}</span
            >严重偏科
          </div>
        </template>
      </a-table>
    </template>
    <no-data v-else />
    <div class="tip" style="margin-top: 18px">
      <div v-if="type === 1">
        <div>
          波动生：以某次考试为对比入口，检索出波动幅度较大的学生，分为上升波动（处于成绩提升趋势）及下降波动（处于成绩下降趋势）。对上升波动的学生给予肯定，激励其保持上升趋势；对下降波动学生，辅助分析原因并给予调整建议和鼓励。特别是排名靠前或临界状态的学生，可进一步查看学生报告了解历次发展情况，进行波动性深度分析。
        </div>
        <div>注：不参与统计的学生不参与此处计算，也不在此处显示</div>
      </div>
      <div v-if="type === 2">
        <div>
          拔尖生（优生劣科）：了解拔尖生群体分布情况，以及优生劣科情况（拔尖生存在“严重偏科”的学科）。优生劣科可结合学生自身的学习特点分析，是否在本学科存在学有余力但方法不当或兴趣不高的情况，如存在应适当引导学生以总分为前提进行偏科学科的学习方法或学习状态的调整，同时也可提升班级总分高分群体占比。
        </div>
        <div>注：1.标橙学生为优生劣科学生</div>
        <div>2.不参与统计的学生不参与此处计算，也不在此处显示</div>
      </div>
      <div v-if="type === 3">
        <div>
          后进生（潜力学科）：了解后进生群体分布情况，以及潜力学科情况（后进生存在单科排名大幅度优于总分排名的学科）。后进生的潜力学科，可依据学生情况针对性分析，是否存在学有余力但学习动力不足的问题导致仅兴趣或天赋学科成绩较好，其他学科成绩滞后的情况。如存在应采取相应手段激发学生学习动力，并给予潜力学科肯定，如给予学生该兴趣学科的生涯规划指导等。
        </div>
        <div>注：1.标蓝学生为潜力生</div>
        <div>2.不参与统计的学生不参与此处计算，也不在此处显示</div>
      </div>
      <div v-if="type === 4">
        <div>
          跟踪生：仅当在报告设置中导入了跟踪生且跟踪生对应成功才显示。跟踪生（目标跟踪生）即为实现上线目标最稳定的学生群体，为目标基础保底儿。可从两方面设置
        </div>
        <div>
          一、（基于目标）结合学生入口成绩及（中）高考预期，确定学校跟踪生名单，各班数量不均。
        </div>
        <div>
          二、（基于管理）每班确定N名跟踪生，多为成绩稳定的尖子生，每班数量均匀。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import chart from "../../components/charts.vue";
import scatter from "./components/scatter.vue";
import { getstatdevelopanalysiscomparelist } from "@/core/api/academic/common";
import { exportHeadReportNew } from "@/core/api/academic/headReport";
import exportBtn from "@/views/academic/components/exportBtn.vue";
import {
  waveStudent,
  getToporbackward,
  getTrack,
} from "@/core/api/newExamAcademic/director";
import {
  waveData,
  unionToporbackward,
  unionTrack,
} from "@/core/api/newExamAcademic/union/head";
export default {
  components: {
    // chart,
    scatter,
    exportBtn,
  },
  props: {
    classNum: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      type: 1,
      wave: {},
      selectOptions: [],
      series: [],
      defineOptions: {},
      tableData: [],
      columns: [],
      topOrBackward: 10,
      examList: [],
      compareStatId: undefined,
      waveType: "upStudents",
      requestFunc: exportHeadReportNew,
      tableReactiveKey: Math.random(),
      scatterKey: Math.random(),
    };
  },
  mounted() {},
  methods: {
    getShowScatter() {
      if (this.$route.query.examType == 1) {
        return this.$parent.topData.isSchoolDisplayReportStudentRank == 1;
      } else {
        return true;
      }
    },
    handleClick(record) {
      const { href } = this.$router.resolve({
        path: "/academic/new-exam/student-report",
        query: {
          ...this.$route.query,
          studentName: record.name,
          studentId: record.studentId,
          subjectId: 0,
          classNum: this.classNum,
          level: this.$route.query.level,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
    compareStatIdChange() {
      this.setWave();
    },
    async getCondition(loadingInstance, io) {
      const res = await getstatdevelopanalysiscomparelist({
        statId: this.$route.query.id,
        classNum: this.classNum,
        viewSchoolId: this.$route.query.schoolId,
      }).catch(() => {
        this.closeLoading(loadingInstance, io);
      });
      this.examList = res.data.data;
      if (res.data.data.length) {
        this.compareStatId = res.data.data[0].id;
        this.setWave();
      }
      this.closeLoading(loadingInstance, io);
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.student);
        });
      }
    },
    async setWave() {
      if (this.examList.length) {
        this.defineOptions = null;
        let res;
        if (this.$route.query.examType == 1) {
          res = await waveData({
            id: this.$route.query.id,
            compareStatId: this.compareStatId,
            classNum: this.classNum,
            viewSchoolId: this.$route.query.schoolId,
          });
        } else {
          res = await waveStudent({
            id: this.$route.query.id,
            compareStatId: this.compareStatId,
            classNum: this.classNum,
          });
        }

        let data = res.data.data[this.waveType];
        const arr = data.map((item) => {
          const totalItem = item.subjects.find((it) => it.subjectId === 0);
          return [
            totalItem.rank,
            totalItem.rank + totalItem.rankWave,
            item.name,
          ];
        });
        const compareRank = arr.map((item) => item[1]);
        const rank = arr.map((item) => item[0]);
        const maxCompareRank = Math.max(...compareRank);
        const maxRank = Math.max(...rank);

        this.tableData = res.data.data[this.waveType];
        if (this.tableData.length) {
          let subjects = this.tableData[0].subjects;
          this.columns = [
            {
              title: "姓名",
              width: 75,
              align: "center",
              dataIndex: "name",
              scopedSlots: {
                customRender: "name",
              },
            },
            ...subjects.flatMap((item, index) => [
              {
                title: item.subjectName,
                dataIndex: "subject" + index,
                align: "center",
                width: 85,
                customRender: (text, record) => record.subjects[index]["score"],
              },
              {
                title:
                  item.subjectId == 0 ? "校排名" : item.subjectName + "排名",
                dataIndex: "subjectRank" + index,
                width: 100,
                align: "center",
                customRender: (text, record) => record.subjects[index]["rank"],
              },
              {
                title: "增幅",
                dataIndex: "rankWave" + index,
                align: "center",
                width: 85,
                scopedSlots: {
                  slot: "rankWave",
                },
                customRender: (text, record) => {
                  if (record.subjects[index]["rankWave"] > 0) {
                    return (
                      <span>
                        <a-icon
                          v-if="record.rankWave > 0"
                          type="arrow-up"
                          style="color:#67c23a"
                        />
                        {record.subjects[index]["rankWave"]}
                      </span>
                    );
                  } else if (record.subjects[index]["rankWave"] < 0) {
                    return (
                      <span style="color:'#fc7070'">
                        <a-icon type="arrow-down" style="color: #fc7070" />
                        {record.subjects[index]["rankWave"]}
                      </span>
                    );
                  } else {
                    return <span> {record.subjects[index]["rankWave"]}</span>;
                  }
                },
              },
            ]),
          ];
          if (
            this.$parent.topData.isSchoolDisplayReportStudentRank == 0 &&
            this.$route.query.examType == 1
          ) {
            this.columns = this.columns.filter(
              (item) => item.title.indexOf("排名") == -1
            );
          }
        }
        if (
          (this.$route.examType == 1 &&
            this.$parent.topData.isSchoolDisplayReportStudentRank == 1) ||
          this.$route.examType != 1
        ) {
          this.scatterKey = Math.random();
          this.defineOptions = {
            tooltip: {
              axisPointer: {
                type: "shadow",
              },
              formatter: function (param) {
                return `${param.data[2]}:${param.data[0]},${param.data[1]}`;
              },
            },
            toolbox: {
              feature: {
                dataZoom: {},
              },
            },
            dataset: [
              {
                source: arr,
              },
              {
                transform: {
                  type: "ecStat:regression",
                  config: { method: "polynomial" },
                },
              },
            ],
            xAxis: {
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "rgba(0, 0, 0, 0.45)",
                },
              },
              axisLine: {
                show: true,
                lineStyle: { color: "rgba(0, 0, 0, 0.3)" },
              },
              splitLine: { show: false },
            },
            yAxis: {
              axisLine: {
                show: true,
                lineStyle: { color: "rgba(0, 0, 0, 0.3)" },
              },
              axisTick: {
                show: true,
                lineStyle: { color: "rgba(0, 0, 0, 0.3)" },
              },
              splitLine: { show: false },
            },
            grid: {
              left: "2%",
              right: "2%",

              bottom: 0,
              containLabel: true,
            },
            series: [
              {
                symbolSize: 10,
                name: "",
                type: "scatter",
                datasetIndex: 0,
                markLine: {
                  lineStyle: {
                    type: "solid",
                    color: "#FF9776",
                  },
                  tooltip: {
                    show: false,
                  },
                  data: [
                    [
                      {
                        coord: [0, 0],
                        symbol: "none",
                      },
                      {
                        coord: [maxRank, maxCompareRank],
                        symbol: "none",
                      },
                    ],
                  ],
                },
                label: {
                  show: true,
                  position: "top",
                  color: "inherit",
                  fontSize: 9,
                  formatter: function (params) {
                    return `${params.data[2]}`;
                  },
                },
              },
            ],
          };
        }
      }
    },
    async getToporbackwardData() {
      let res;
      if (this.$route.query.examType == 1) {
        res = await unionToporbackward({
          classNum: this.classNum,
          id: this.$route.query.id,
          topOrBackward: this.topOrBackward,
          type: this.type == 2 ? 1 : 2,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getToporbackward({
          classNum: this.classNum,
          id: this.$route.query.id,
          topOrBackward: this.topOrBackward,
          type: this.type == 2 ? 1 : 2,
        });
      }

      let subjects = [];
      if (res.data.data.length) {
        subjects = res.data.data[0].subjects;
      }
      this.columns = [
        {
          dataIndex: "name",
          title: "姓名",
          align: "center",
          width: 75,
          scopedSlots: { customRender: "name" },
        },
        {
          dataIndex: "potentialSubject",
          title: "潜力学科",
          align: "center",
          width: 250,
          scopedSlots: { customRender: "potentialSubject" },
        },
        ...subjects.flatMap((item, index) => {
          return [
            {
              title: item.subjectName,
              dataIndex: "subjects" + index,
              align: "center",
              width: 100,
              customRender: (text, record) => record.subjects[index]["score"],
            },
            {
              title: item.subjectName + "排名",
              dataIndex: "subjects-rank" + index,
              align: "center",
              width: 100,
              customRender: (text, record) => record.subjects[index]["rank"],
            },
          ];
        }),
      ];
      if (
        this.$parent.topData.isSchoolDisplayReportStudentRank == 0 &&
        this.$route.query.examType == 1
      ) {
        this.columns = this.columns.filter(
          (item) => item.title.indexOf("排名") == -1
        );
      }
      this.tableData = res.data.data;
      this.tableReactiveKey = Math.random();
    },
    searchSubmit() {
      if (!/(^[1-9]\d*$)/.test(this.topOrBackward)) {
        this.$message("请输入正整数");
        return;
      } else {
        this.typeChange();
      }
    },
    async setTrack() {
      let res;
      if (this.$route.query.examType == 1) {
        res = await unionTrack({
          classNum: this.classNum,
          id: this.$route.query.id,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getTrack({
          classNum: this.classNum,
          id: this.$route.query.id,
        });
      }

      let subjects = [];
      if (res.data.data.length) {
        subjects = res.data.data[0].subjects;
      }
      this.columns = [
        {
          dataIndex: "name",
          title: "姓名",
          align: "center",
          width: 75,
          scopedSlots: { customRender: "name" },
        },
        {
          dataIndex: "potentialSubject",
          title: "薄弱学科",
          align: "center",
          width: 250,
          scopedSlots: { customRender: "potentialSubject" },
        },
        ...subjects.flatMap((item, index) => {
          if (
            this.$parent.topData.isSchoolDisplayReportStudentRank == 0 &&
            this.$route.query.examType == 1
          ) {
            return [
              {
                title: item.subjectName,
                dataIndex: "subjects" + index,
                align: "center",
                width: 100,
                customRender: (text, record) => record.subjects[index]["score"],
              },
            ];
          }
          return [
            {
              title: item.subjectName,
              dataIndex: "subjects" + index,
              align: "center",
              width: 100,
              customRender: (text, record) => record.subjects[index]["score"],
            },
            {
              title: item.subjectName + "排名",
              dataIndex: "subjects-rank" + index,
              align: "center",
              width: 100,
              customRender: (text, record) => record.subjects[index]["rank"],
            },
          ];
        }),
      ];
      this.tableData = res.data.data;
      this.tableReactiveKey = Math.random();
    },
    typeChange() {
      this.defineOptions = null;
      this.series = [];
      this.tableData = [];
      if (this.type === 1) {
        this.getCondition();
      } else if (this.type === 2) {
        this.topOrBackward = !this.topOrBackward ? 20 : this.topOrBackward;
        this.getToporbackwardData();
      } else if (this.type === 3) {
        this.topOrBackward = !this.topOrBackward ? 20 : this.topOrBackward;
        this.getToporbackwardData();
      } else if (this.type === 4) {
        this.setTrack();
      }
    },
    validateWave() {
      if (
        !/(^[1-9]\d*$)/.test(this.wave.upTotal) ||
        !/(^[1-9]\d*$)/.test(this.wave.upMax)
      ) {
        this.$message("请输入正整数");
        return;
      } else if (!this.wave.upCompareId) {
        this.$message({
          showClose: false,
          message: "请选择对比考试",
          type: "error",
        });
      } else {
        // getdata
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";

.operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}

.charts {
  margin-bottom: 18px;

  .filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0;

    span {
      margin: 0 8px;
    }

    div {
      margin-right: 18px;
    }
  }
}

.classNum {
  margin-bottom: 18px;
}

.potentialSubject {
  span {
    color: red;
  }

  text-align: left;
}
</style>
