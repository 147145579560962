<template>
  <div class="headerTable">
    <table
      v-if="data.length"
      cellpadding="0"
      cellspacing="1"
      bordercolor="#EBEEF5"
      border="0"
    >
      <tr>
        <th rowspan="2" class="td1">学生</th>
        <th v-if="showColumn('schoolRank')" rowspan="2" class="td2">校排名</th>
        <th
          v-if="showColumn('schoolRank') && $route.query.examType == 1"
          rowspan="1"
          class="td2"
        >
          整体排名
        </th>
        <th :colspan="objectiveQuestionsLength">客观题</th>
        <th :colspan="subjectiveQuestionsLength">主观题</th>
      </tr>
      <tr>
        <th
          v-for="(item, index) in head.objectiveQuestions"
          :key="'objectiveQuestionsQuestionOrder' + index"
        >
          {{ item.questionOrder }}
        </th>
        <th
          v-for="(item, index) in head.subjectiveQuestions"
          :key="'subjectiveQuestionsQuestionOrder' + index"
        >
          {{ item.questionOrder }}
        </th>
      </tr>
      <tr>
        <th
          :colspan="
            showColumn('schoolRank') ? ($route.query.examType == 1 ? 3 : 2) : 1
          "
          class="td1"
        >
          正确答案/满分分值
        </th>
        <th
          v-for="(item, index) in head.objectiveQuestions"
          :key="'objectiveQuestionsError' + index"
        >
          <span v-if="item.questionOrder === '客观题'">{{ item.score }}</span>
          <span v-else>{{ item.rightAnswer }}</span>
        </th>
        <th
          v-for="(item, index) in head.subjectiveQuestions"
          :key="'subjectiveQuestionsError' + index"
        >
          {{ item.score }}
        </th>
      </tr>
      <tr v-for="(item, index) in body" :key="'body' + index">
        <td class="td1">
          <el-button type="text" @click="toStudentDetail(item)">{{
            item.name
          }}</el-button>
        </td>
        <td v-if="showColumn('schoolRank')" class="td2">
          {{ item.schoolRank }}
        </td>
        <td
          v-if="showColumn('schoolRank') && $route.query.examType == 1"
          class="td2"
        >
          {{ item.unionRank }}
        </td>
        <td
          v-for="(it, ind) in head.objectiveQuestions"
          :key="'bodyObjectiveQuestions' + ind"
        >
          <span>{{ getQuestionOrder(item, it) }}</span>
        </td>
        <td
          v-for="(it, ind) in head.subjectiveQuestions"
          :key="'bodySubjectiveQuestions' + ind"
        >
          <span>{{ getsubjectiveQuestionOrder(item, it) }}</span>
        </td>
      </tr>
    </table>
    <no-data v-else />
  </div>
</template>

<script>
export default {
  name: "HeaderTable",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      head: {},
      body: {},
      objectiveQuestionsLength: 0,
      subjectiveQuestionsLength: 0,
      statId: this.$route.query.id,
    };
  },
  watch: {
    data(val) {
      if (val.length) {
        this.head = {
          objectiveQuestions: val[0].questions.filter(
            (item) => item.isObjective == 1
          ),
          subjectiveQuestions: val[0].questions.filter(
            (item) => item.isObjective == 0
          ),
        };
        this.body = [...val];
        this.subjectiveQuestionsLength = this.head.subjectiveQuestions.length;
        this.objectiveQuestionsLength = this.head.objectiveQuestions.length;
      }
    },
  },
  created() {},
  methods: {
    showColumn(field) {
      if (this.$route.query.examType == 1) {
        return this.topData.isSchoolDisplayReportClassRank == 1;
      } else {
        if (this.data.length) {
          const result = this.data.every((item) => item[field] === null);
          return !result;
        }
        return true;
      }
    },
    toStudentDetail(item) {
      const { href } = this.$router.resolve({
        path: "/academic/new-exam/student-report",
        query: {
          ...this.$route.query,
          studentName: item.name,
          studentId: item.studentId,
          subjectId: 0,
          classNum: this.classNum,
          level: this.$route.query.level,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
    getQuestionOrder(item, it) {
      const data = item.questions.find(
        (i) => i.questionOrder === it.questionOrder
      );

      if (it.questionOrder === "客观题") {
        return data.lostScore;
      }
      return data.wrongAnswer;
    },
    getsubjectiveQuestionOrder(item, it) {
      const data = item.questions.find(
        (i) => i.questionOrder === it.questionOrder
      );
      return data.lostScore;
    },
  },
};
</script>
<style lang="scss" scoped>
.headerTable {
  width: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 20px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
    background: #999;
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
    border-radius: 10px;
    background: #ededed;
  }
  table {
    width: 100%;
    // border-collapse: collapse;
    background-color: #ebeef5;
    border-collapse: separate;
    tr {
      th {
        min-width: 80px;
        height: 37px;
        background-color: #f2f5f8;
        font-size: 14px;
        text-align: center;
        color: #101011;
      }
      & :last-child {
        border-right: none;
      }
      td {
        min-width: 80px;
        height: 42px;
        background-color: #fff;
        text-align: center;
        font-size: 14px;
        text-align: center;
        color: #909399;
      }
    }
  }
}
.td1 {
  position: sticky;
  z-index: 1;
  left: 0;
}
.td2 {
  position: sticky;
  z-index: 1;
  left: 80px;
}
table thead tr {
  border: none;
  outline-color: #fff;
  outline-style: solid;
  outline-width: 1px;
}
</style>
