export const knowledgePointCol = [
  {
    dataIndex: "knowledgeName",
    title: "知识点",
    align: "center",
  },
  {
    dataIndex: "score",
    title: "满分分值",
    align: "center",
    width: 120,
  },
  {
    dataIndex: "countQuestion",
    title: "题量",
    align: "center",
    width: 80,
  },
  {
    dataIndex: "questionOrders",
    title: "题号",
    align: "center",
    width: 80,
  },
  {
    dataIndex: "averageScore",
    title: "平均分",
    width: 100,
    align: "center",
  },
  {
    dataIndex: "scoreRate",
    title: "得分率",
    align: "center",
    width: 100,
  },
  {
    dataIndex: "schoolScoreRate",
    title: "校得分率",
    width: 120,
    align: "center",
  },
  {
    dataIndex: "countRight",
    title: "满分人数",
    width: 120,
    align: "center",
  },
  {
    dataIndex: "countZero",
    title: "零分人数",
    width: 120,
    align: "center",
  },
];
