<template>
  <div ref="paper" v-lazyChart="{ fn: getCondition }" class="container">
    <div class="title">试卷分析</div>
    <div class="subjects">
      <div
        v-for="(item, index) in subjectList"
        :key="index"
        :class="['subject', subjectId == item.id ? 'subject-active' : '']"
        @click="handleClick(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <!-- <div class="dashborad">
      <div class="dashborad-item primary">
        <div class="dashborad-item-title">
          {{ (paperQualityData.scoreRate || 0 / 1).toFixed(2) || "" }}
        </div>
        <div>难度</div>
      </div>
      <div class="dashborad-item warning">
        <div class="dashborad-item-title">
          {{ paperQualityData.reliability || 0 }}
        </div>
        <div>信度</div>
      </div>
      <div class="dashborad-item success">
        <div class="dashborad-item-title">
          {{ (paperQualityData.discrimination || 0 / 1).toFixed(2) || "" }}
        </div>
        <div>区分度</div>
      </div>
    </div> -->
    <div class="operate">
      <div class="sub-title">应答情况分析</div>
      <div class="radio">
        <a-radio-group
          v-model="type"
          button-style="solid"
          style="margin-right: 18px"
          @change="typeChagne"
        >
          <a-radio-button value="1"> 图 </a-radio-button>
          <a-radio-button value="2"> 表 </a-radio-button>
        </a-radio-group>
        <a-radio-group
          v-model="category"
          button-style="solid"
          @change="categoryChange"
        >
          <a-radio-button value="1"> 题目 </a-radio-button>
          <a-radio-button v-if="$parent.topData.hasKnowledge" value="2">
            知识点
          </a-radio-button>
        </a-radio-group>
      </div>
    </div>
    <template v-if="type == 1">
      <chart
        v-if="category == 1"
        :key="tokenKey"
        :define-options="defineOptions"
        :series="series"
        :data-zoom="20"
      />
      <radar
        v-if="category == 2"
        :key="tokenKey"
        :data="abilityOptions"
        height="400px"
      />
    </template>
    <template v-if="type == 2">
      <exTable
        :key="'exTable' + tokenKey"
        :columns="columns"
        :data-source="tableData"
        :scroll="{ x: 'max-content' }"
      >
      </exTable>
    </template>
    <div class="sub-title">学生应答反馈</div>
    <headerTable :top-data="topData" :data="headerTableData" />
  </div>
</template>

<script>
import chart from "../../components/charts.vue";
import exTable from "../../components/exTable.vue";
import radar from "./components/radar.vue";
import { knowledgePointCol } from "./columns";
import headerTable from "../../components/headerTable.vue";
import { getstatsubjectoption } from "@/core/api/academic/common";
import {
  getQuetions,
  getKnowledge,
  getbzrreportpaperanaly,
} from "@/core/api/newExamAcademic/director";
import {
  uniongetbzrreportpaperanalyQuestion,
  uniongetbzrreportpaperanalyKnowledge,
  uniongetbzrreportpaperanalyStudent,
} from "@/core/api/newExamAcademic/union/head";
import { getresearchreportpaperanalypaperquality } from "@/core/api/academic/teachingReport";
export default {
  name: "",
  components: {
    chart,
    radar,
    exTable,
    headerTable,
  },
  props: {
    classNum: {
      type: [String, Number],
      default: "",
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      paperQualityData: {},
      type: "1",
      category: "1",
      subjectList: [
        {
          subjectName: "语文",
          subjectId: 1,
        },
      ],
      columns: [],
      tableData: [],
      subjectId: 1,
      defineOptions: {},
      series: [],
      tokenKey: Math.random(),
      headerTableData: [],
      questionCol: [
        {
          dataIndex: "questionOrder",
          title: "题目",
          width: 80,
          align: "center",
        },
        {
          dataIndex: "score",
          title: "满分分值",
          width: 100,
          align: "center",
        },
        {
          dataIndex: "averageScore",
          title: "平均分",
          width: 80,
          align: "center",
        },
        {
          dataIndex: "scoreRate",
          title: "得分率",
          width: 80,
          align: "center",
        },
        {
          dataIndex: "schoolScoreRate",
          title: "得分率（年级）",
          width: 150,
          align: "center",
          customRender: (text, record) => {
            let classStyle = {
              class: record.schoolScoreRate > record.scoreRate ? "ft-red" : "",
            };
            return <span {...classStyle}>{record.schoolScoreRate} </span>;
          },
        },
        ...(this.$route.query.examType == 1 &&
        this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1
          ? [
              {
                dataIndex: "unionScoreRate",
                title: "整体得分率",
                width: 110,
                align: "center",
              },
            ]
          : []),
        {
          dataIndex: "countZero",
          title: "零分人数",
          width: 100,
          align: "center",
        },

        {
          dataIndex: "countRight",
          title: "满分人数",
          width: 100,
          align: "center",
        },
        {
          dataIndex: "options",
          title: "客观题选项分布",
          align: "center",
          customRender: (text, record) => {
            if (record.options) {
              return record.options.map((it) => {
                let width = this.getPercent(
                  it.total,
                  record.countWrong + record.countRight,
                  record.options
                );
                let style = {
                  style: `width:${width}px`,
                };
                return (
                  <div class="flex">
                    <div class="label">{it.item}</div>
                    {it.total >= 0 ? (
                      <div class="out">
                        <div class="progress" {...style}></div>
                      </div>
                    ) : (
                      <div class="none"></div>
                    )}
                    <div class="total">{it.total}</div>
                  </div>
                );
              });
            }
          },
        },
        {
          dataIndex: "countWrong",
          title: "答错人数",
          width: 100,
          align: "center",
        },
        {
          dataIndex: "wrongStudents",
          title: "答错名单",
          width: 350,
          align: "left",
          customRender: (text, record) => {
            let data =
              record.extend || record.wrongStudents.length <= 3
                ? record.wrongStudents
                : record.wrongStudents.slice(0, 3);
            let button;
            if (record.wrongStudents.length > 3) {
              button = record.extend ? (
                <a-button
                  type="link"
                  vOn:click={() => this.$set(record, "extend", false)}
                >
                  收起
                </a-button>
              ) : (
                <a-button
                  type="link"
                  vOn:click={() => this.$set(record, "extend", true)}
                >
                  查看更多
                </a-button>
              );
            }

            return (
              <div class="nameList">
                <span style="margin-bottom: 8px; margin-right: 8px">
                  {record.level}
                </span>
                {...data.map((item) => (
                  <a-button
                    type="link"
                    vOn:click={() => this.handleClick(record, item)}
                  >
                    {item.name}
                  </a-button>
                ))}
                {button}
              </div>
            );
          },
        },
        {
          dataIndex: "knowledgeNames",
          title: "知识点",
          align: "center",
        },
        // 是否有知识点 如果有才显示
      ],
    };
  },
  created() {},
  methods: {
    handleClick(item) {
      this.subjectId = item.id;
      this.getbzrreportpaperanalyData();
      this.categoryChange();
      this.getresearchreportpaperanalypaperqualityData();
    },
    async getbzrreportpaperanalyData() {
      let res;
      if (this.$route.query.examType == 1) {
        res = await uniongetbzrreportpaperanalyStudent({
          classNum: this.classNum,
          id: this.$route.query.id,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getbzrreportpaperanaly({
          classNum: this.classNum,
          id: this.$route.query.id,
          subjectId: this.subjectId,
        });
      }
      this.headerTableData = res.data.data;
    },
    async getresearchreportpaperanalypaperqualityData() {
      const res = await getresearchreportpaperanalypaperquality({
        id: this.$route.query.id,
        subjectId: this.subjectId,
      });
      this.paperQualityData = res.data.data;
    },
    async getCondition(loadingInstance, io) {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        classNum: this.classNum,
        requestPage: 3,
        hasTotal: 0,
        viewSchoolId: this.$route.query.schoolId,
      }).catch(() => {
        this.closeLoading(loadingInstance, io);
      });
      this.subjectList = res.data.data;
      if (res.data.data.length) {
        this.handleClick(res.data.data[0]);
      }
      this.closeLoading(loadingInstance, io);
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.paper);
        });
      }
    },
    getPercent(num, total, options) {
      let totalNumber = options.reduce((total, item) => {
        return total + Number(item.total);
      }, 0);
      if (!num || !totalNumber) {
        return 0;
      } else {
        return (num / totalNumber) * 80;
      }
    },
    typeChagne() {
      if (this.category == 1) {
        this.setQuesitonChart();
      } else {
        this.setKownlagePoints();
      }
    },
    async setQuesitonChart() {
      let res;
      if (this.$route.query.examType == 1) {
        res = await uniongetbzrreportpaperanalyQuestion({
          classNum: this.classNum,
          id: this.$route.query.id,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getQuetions({
          classNum: this.classNum,
          id: this.$route.query.id,
          subjectId: this.subjectId,
        });
      }

      let col = this.questionCol.filter((item) => {
        if (this.$parent.topData.hasKnowledge) {
          return item;
        } else {
          if (item.title !== "知识点") {
            return item;
          }
        }
      });
      this.columns = [...col];
      this.tableData = res.data.data;
      if (res.data.data.length) {
        this.defineOptions = {
          grid: {
            containLabel: true,
          },
          xAxis: {
            type: "category",
            axisTick: {
              show: false,
            },
            axisLabel: {
              rotate: "45",
              overflow: "truncate",
              width: 75,
            },
            data: [...res.data.data.map((item) => item.questionOrder)],
          },
        };
        this.series = [
          {
            type: "bar",
            color: "#2474EDD9",
            name: "班级得分率",
            barMaxWidth: 40,
            label: {
              show: true,
              position: "top",
              color: "inherit",
              formatter: ({ value }) => value + "%",
            },
            data: [...res.data.data.map((item) => item.scoreRate)],
          },
          {
            type: "line",
            color: "#FF9268",
            name: "年级得分率",
            symbol: "circle",
            data: [...res.data.data.map((item) => item.schoolScoreRate)],
          },
        ];
        if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          this.series.push({
            type: "line",
            name: "整体得分率",
            symbol: "circle",
            data: [...res.data.data.map((item) => item.unionScoreRate)],
          });
        }
      }
      this.tokenKey = Math.random();
    },
    async setKownlagePoints() {
      let res;
      if (this.$route.query.examType == 1) {
        res = await uniongetbzrreportpaperanalyKnowledge({
          classNum: this.classNum,
          id: this.$route.query.id,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getKnowledge({
          classNum: this.classNum,
          id: this.$route.query.id,
          subjectId: this.subjectId,
        });
      }

      this.tableData = res.data.data;
      this.abilityOptions = {
        legend: {
          itemGap: 35,
        },
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
        radar: {
          shape: "",
          indicator: res.data.data.map((item) => {
            return {
              name: item.knowledgeName,
              max: 100,
            };
          }),
        },
        series: [
          {
            name: "得分率",
            type: "radar",
            data: [
              {
                value: res.data.data.map((item) => item.scoreRate),
                name: "班级",
              },
              {
                value: res.data.data.map((item) => item.schoolScoreRate),
                name: "年级",
              },
            ],
          },
        ],
      };
      this.columns = [...knowledgePointCol];
      this.tokenKey = Math.random();
    },
    studentDetail(item) {
      const { href } = this.$router.resolve({
        path: "/academic/new-exam/student-report",
        query: {
          id: this.statId,
          studentId: item.studentId,
        },
      });
      window.open(href, "_blank");
    },
    showStudentList(index, row) {
      if (row.showMore) {
        return true;
      } else if (!row.showMore && index < 5) {
        return true;
      } else {
        return false;
      }
    },
    categoryChange() {
      this.getresearchreportpaperanalypaperqualityData();
      if (this.category == 1) {
        this.setQuesitonChart();
      } else {
        this.setKownlagePoints();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";
.subjects {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #d5d6db;
  box-sizing: border-box;
  padding: 16px 16px 0 16px;
  margin-bottom: 18px;
  .subject {
    height: 32px;
    border-radius: 4px;
    border: 1px solid #d5d6db;
    line-height: 32px;
    color: #0a1119ff;
    font-size: 14px;
    letter-spacing: 1px;
    box-sizing: border-box;
    padding: 0 18px;
    margin-right: 8px;
    margin-bottom: 16px;
    cursor: pointer;
  }
  .subject-active {
    background-color: #2474edff;
    color: #ffffff;
  }
}
.dashborad {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  .dashborad-item + .dashborad-item {
    margin-left: 24px;
  }
  .dashborad-item {
    flex: 1;
    font-weight: 400;
    color: #3e4551;
    border-radius: 8px;
    box-sizing: border-box;
    text-align: center;
    padding: 12px 0;
    font-size: 12px;
    .dashborad-item-title {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }
}
.primary {
  background: #f3f8ffff;
  .dashborad-item-title {
    color: #2474edff;
  }
}
.warning {
  background: #fff8f3ff;
  .dashborad-item-title {
    color: #ff7a05ff;
  }
}

.success {
  background: #f3fcf8ff;
  .dashborad-item-title {
    color: #2bb472ff;
  }
}
.operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  .label {
    width: 50px;
    text-align: left;
  }
  .out {
    width: 80px;
    background-color: #eee;
    height: 8px;
    .progress {
      height: 8px;
      background-color: #63a8ec;
    }
  }
  .none {
    background-color: #eee;
    height: 8px;
    flex: 1;
  }
  .total {
    width: 45px;
    text-align: left;
    margin-left: 5px;
  }
}
.nameList {
  cursor: pointer;
  span {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.green {
  color: #67c23a;
  text-decoration: underline;
}
.red {
  color: #f56c6c;
  text-decoration: underline;
}
</style>
